import React from 'react'
import Slider from '@mui/material/Slider'
import BaseQuestion from '../baseQuestion'
import PropTypes from 'prop-types'
import { debounce } from 'lodash'

const DoubleSlideBar = ({ displayComments, disabled, saveResponse, fullResponse, reponse, question, comment, commentUser, showComments, questionHelpText }) => {
  const [options, setOptions] = React.useState([])
  const [marks, setMarks] = React.useState([])
  const [step, setStep] = React.useState(0)
  const [responseState, setResponseState] = React.useState({
    impact: { value: 0, choiceIndex: 0, option: null, nextOption: null, pourcentage: 0 },
    risk: { value: 0, choiceIndex: 0, option: null, nextOption: null, pourcentage: 0 }
  })
  const [sliderColor, setSliderColor] = React.useState({ impact: '', risk: '' })

  React.useEffect(() => {
    if (question?.option) {
      setOptions(question.option)
    }
  }, [question])

  React.useEffect(() => {
    if (options.length > 0) {
      const long = options.length
      setStep(100 / (long * 20))
      const list = options.map((op, index) => {
        if (op) {
          const step = (100 - 10) / (long - 1)
          const mark = {
            value: 10 + (index * step),
            label: index + 1,
            text: op.text ? op.text : ''
          }
          return mark
        }
      })
      setMarks(list)
    }
  }, [options])

  React.useEffect(() => {
    if (reponse) {
      setResponseState({
        impact: reponse.impact || responseState.impact,
        risk: reponse.risk || responseState.risk
      })
    }
  }, [reponse])

  const debouncedSaveResponse = React.useCallback(debounce((state) => {
    saveResponse(state)
  }, 300), [saveResponse])

  const handleSliderChange = (e, type) => {
    const value = e.target.value
    const step = (100 - 10) / (options.length - 1)
    const ch = (value - 10 + step) / step
    const Index = Number(ch.toFixed(4).slice(0, -2))
    const choiceIndex = (Math.round(ch * 10) / 10)
    const pourcentage = Math.floor((Index - Math.floor(Index)) * 100)
    const clearIndex = Math.floor(Index)
    const option = options[clearIndex - 1]?.text || null
    const nOption = options[clearIndex]?.text || null

    setResponseState(prevState => ({
      ...prevState,
      [type]: { value, choiceIndex, option, nextOption: nOption, pourcentage, question: question.uuid }
    }))
  }

  const handleSave = () => {
    const response = {
      complexValue: responseState,
      question: question.uuid,
      state: 'draft'
    }
    debouncedSaveResponse(response)
  }

  React.useEffect(() => {
    if (responseState.impact?.choiceIndex >= 1) {
      setSliderColor(prev => ({ ...prev, impact: '#3096d1' }))
    } else {
      setSliderColor(prev => ({ ...prev, impact: '#de6868' }))
    }
    if (responseState.risk?.choiceIndex >= 1) {
      setSliderColor(prev => ({ ...prev, risk: '#3096d1' }))
    } else {
      setSliderColor(prev => ({ ...prev, risk: '#de6868' }))
    }
  }, [responseState])

  return (
    <BaseQuestion
      displayComments={displayComments} 
      disabled={disabled} 
      sendData={handleSave} 
      questionHelpText={questionHelpText}
      reponse={fullResponse} 
      showComments={showComments} 
      commentUser={commentUser} 
      comment={comment} 
      question={question}
    >
      <h5 className="text-lg pb-2 px-2">{question.text}</h5>
      <div className='w-full pl-4 flex gap-5'>
        <div className='w-2/3'>
          <div>
            <h5 className="text-lg">Impact</h5>
            <Slider
              disabled={disabled || new Set(['ignored', 'validated']).has(fullResponse?.state)}
              aria-label="Custom marks"
              value={responseState.impact?.value || 0}
              onChange={(e) => handleSliderChange(e, 'impact')}
              onChangeCommitted={handleSave}
              valueLabelFormat={responseState.impact?.choiceIndex || 0}
              step={step}
              marks={marks}
              sx={{ color: sliderColor.impact, '& .MuiSlider-rail': { color: sliderColor.impact } }}
              valueLabelDisplay="auto"
            />
          </div>
          <div>
            <h5 className="text-lg">Urgence</h5>
            <Slider
              disabled={disabled || new Set(['ignored', 'validated']).has(fullResponse?.state)}
              aria-label="Custom marks"
              value={responseState.risk?.value || 0}
              onChange={(e) => handleSliderChange(e, 'risk')}
              onChangeCommitted={handleSave}
              valueLabelFormat={responseState.risk?.choiceIndex || 0}
              step={step}
              marks={marks}
              sx={{ color: sliderColor.risk, '& .MuiSlider-rail': { color: sliderColor.risk } }}
              valueLabelDisplay="auto"
            />
          </div>
        </div>
        <div className='flex flex-col gap-1'>
          {marks
            ? marks.map((op, index) => (
              op?.label !== 0 && (
                <div className='flex gap-2' key={index}>
                  <span className='px-1 bg-gray-300 rounded-sm max-h-fit'>{op?.label || index + 1}</span>
                  <p className='text-gray-800 text-md'>{op.text || undefined}</p>
                </div>
              )
            ))
            : undefined
          }
          <div />
        </div>
      </div>
    </BaseQuestion>
  )
}

DoubleSlideBar.propTypes = {
  text: PropTypes.string,
  displayComments: PropTypes.bool,
  disabled: PropTypes.bool.isRequired,
  responses: PropTypes.array,
  commentUser: PropTypes.string,
  saveResponse: PropTypes.func,
  reponse: PropTypes.object,
  question: PropTypes.object.isRequired,
  comment: PropTypes.object,
  questionHelpText: PropTypes.string,
  showComments: PropTypes.bool,
  fullResponse: PropTypes.object
}

export default DoubleSlideBar

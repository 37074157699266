// documentsSlice.js
import { createSelector, createEntityAdapter } from '@reduxjs/toolkit'
import { apiSlice } from '../api/apiSlice'

// Create an entity adapter for documents
const documentsAdapter = createEntityAdapter({
  selectId: (document) => document.id
})

// Define the initial state using the adapter's getInitialState method
const initialState = documentsAdapter.getInitialState({
  status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
  error: null
})

// Inject endpoints into the apiSlice
export const documentsApiSlice = apiSlice.injectEndpoints({
  reducerPath: 'documentsApiSlice',
  endpoints: (builder) => ({
    getDocumentsByType: builder.query({
      query: (docType) => `documents/fetch/type/${docType}`,
      transformResponse: (responseData) => {
        return responseData
      },
      providesTags: ['Documents']
    }),
    downloadDocument: builder.query({
      query: (id) => ({
        url: `documents/download/${id}`,
        responseType: 'blob'
      })
    })
  })
})

// Export hooks for the queries
export const {
  useGetDocumentsByTypeQuery,
  useLazyDownloadDocumentQuery
} = documentsApiSlice

// Create a selector for the documents data
export const selectDocumentsResult = documentsApiSlice.endpoints.getDocumentsByType.select()

// Create a selector for the documents data using createSelector
export const selectDocumentsData = createSelector(
  selectDocumentsResult,
  (documentsResult) => documentsResult.data
)

// Export the entity adapter's selectors
export const {
  selectAll: selectAllDocuments,
  selectById: selectDocumentById
} = documentsAdapter.getSelectors((state) => selectDocumentsData(state) ?? initialState)

/*
transformResponse: (response) => {
  if (response) {
    // Create a URL for the blob response to use in the download link
    const url = window.URL.createObjectURL(response) // Correctly handles the blob response.
    return url
  }
  return null
}
*/

import React from 'react'
import UserListGrid from '../utils/table/userListGrid'
import { selectCurrentUser } from '../../auth/authSlice'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { dfAllowCreator } from '../utils/handleAllowCreator'
import PropTypes from 'prop-types'

function SetUserList({ onChangeCampaign, campaign, onChangeStep, entity }) {
  const { t } = useTranslation()
  const user = useSelector(selectCurrentUser)
  const [entityMembers, setEntityMembres] = React.useState([])

  React.useEffect(() => {
    if (entity.entity_members) {
      setEntityMembres(entity.entity_members)
    } else { setEntityMembres([]) }
  }, [entity])

  React.useEffect(() => {
    const allowCreator = Boolean(campaign.users_list.find(userl => userl.email === user.email))
    onChangeCampaign(campaign = { ...campaign, allow_creator: allowCreator })
  }, [campaign.users_list])

  const handleAllowCreator = (e) => {
    const usersList = campaign.users_list
    const allow = e.target.checked
    const membersList = entityMembers.members
    const client = user
    onChangeCampaign(campaign = { ...campaign, allow_creator: !campaign.allow_creator })
    const selectedList = dfAllowCreator(usersList, membersList, allow, client)
    onChangeCampaign({ ...campaign, users_list: selectedList })
  }

  return (
    <section className="mt-4s">
      <h5 className="block font-medium text-gray-800 pl-2 pb-1 text-xl">{t('consultant.campaign.create.usersList')}</h5>
      <UserListGrid data={entityMembers?.members ? entityMembers.members : []}
        setUsersList={(data) => onChangeCampaign({ ...campaign, users_list: data })}
        usersList={campaign?.users_list ? campaign.users_list : undefined} />
      <div className="form-check flex flex-col">

        <div className="form-check">
          <input
            className="form-check-input border text-white border-gray-300 rounded-sm bg-white checked:bg-teal-600 checked:border-teal-600 
            focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
            type="checkbox"
            id="allowCreator"
            checked={campaign?.allow_creator || false}
            onChange={handleAllowCreator}
            style={{ width: '19px', height: '19px' }}
          />
          <label
            className="form-check-label inline-block text-gray-800 text-lg"
            htmlFor="allowCreator"
            style={{ cursor: 'pointer' }}
          >{entity.entity.consultant.includes(user.id)
            ? <p> {t('consultant.campaign.create.allow_owner')} <small className='text-gray-600'>({user?.email})</small>  </p>
            : <p>{t('consultant.campaign.create.allow_creator')} <small className='text-gray-600'> ({user?.email}) </small></p>
            }

          </label>
        </div>
      </div>

      <div className="flex gap-3">
        <div className="w-4/5 mt-5">
          <button className="bg-teal-600 px-2 py-0.5 rounded-md mb-1 text-gray-100 text-md font-medium float-right p-1">
            <h5 className="text-white  w-max-fit" onClick={(e) => onChangeStep(3)}>{t('common.buttons.next')}</h5>
          </button>
          <button className="bg-gray-300 mx-2 px-2 py-0.5 rounded-md mb-1 text-md font-medium float-right p-1">
            <h5 className="text-[#444444]  w-max-fit " onClick={(e) => onChangeStep(1)}>{t('common.buttons.post')}</h5>
          </button>
        </div>
      </div>
      <div className="h-1"></div>
    </section>
  )
}
SetUserList.propTypes = {
  onChangeStep: PropTypes.func.isRequired,
  step: PropTypes.number.isRequired,
  onChangeCampaign: PropTypes.func.isRequired,
  campaign: PropTypes.object,
  entity: PropTypes.object
}

export default SetUserList

import React from 'react'
import { Outlet, Routes, Route } from 'react-router-dom'
import { useGetUsersQuery } from '../../../features/users/usersSlice'
import DocumentTracking from './documentTracking'

function IndexUser () {
  const { isSuccess: isSuccessAdmins } = useGetUsersQuery()
  return (
    <>
        <Outlet/>
        <Routes>
            <Route path="" exact element={<DocumentTracking isSuccess={isSuccessAdmins}/>}></Route>
        </Routes>
    </>
  )
}

export default IndexUser

import React from 'react'
import { useLocation, Navigate, Outlet } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectCurrentUser } from '../../features/auth/authSlice'
import FirstLogin from './firstlogin'

function RequireAuth ({ allowedRole }) {
  const user = useSelector(selectCurrentUser)
  const location = useLocation()
  return (
    allowedRole.includes(user?.type)
      ? user.first_login
        ? <FirstLogin email={user?.email}/>
        : <Outlet/>
      : <Navigate to="/auth/login" state={{ from: location }} replace />
  )
}

export default RequireAuth

import React, { useRef } from 'react'
import DynamicNavBar from '../../../../components/Bars/DynamicNavBar'
import { importOptions } from '../../DynamicNavbarOptions'
import { useTranslation } from 'react-i18next'
import { read, utils } from 'xlsx'
import ReplayIcon from '@mui/icons-material/Replay'
import ImportResponseModelTable from '../tables/importResponseModelTable'
import ImportQuestionsTable from '../tables/importQuestionsTable'
import { HandleSendFile, GetExcelFile } from './axios_sendFiles'
import DownloadIcon from '@mui/icons-material/Download'
import ImportSurvey from '../tables/importSurvey'
import ImportSurveyTree from '../tables/importSurveyTree'
import ImportFormulasList from '../tables/importFormulas'
import ImportGlobalFormulas from '../tables/importGlobalFormulas'
import ImportTransformationMethod from '../tables/importTransformationMethod'
import ImportAdditionalParamsTable from '../tables/importAdditionalParamsTable'
import { useNavigate } from 'react-router-dom'
import { Clock } from '../../../../components/loadData/clockLoader'
import { useLazyGetSurveyExcelfileQuery } from '../../../../features/questions/questionSlice'

function ImportQuestion () {
  const [questions, setQuestions] = React.useState([])
  const [additionalParameters, setAdditionalParameters] = React.useState([])
  const [loadingImportFile, setLoadingImportFile] = React.useState(false)
  const [models, setModels] = React.useState([])
  const [survey, setSurvey] = React.useState([])
  const [surveyTree, setSurveyTree] = React.useState([])
  const [globalformulas, setGlobalformulas] = React.useState([])
  const [methodesTransformation, setMethodesTransformation] = React.useState([])
  const [formule, setFormule] = React.useState([])
  const [table, setTable] = React.useState('M')
  const [file, setFile] = React.useState()
  const [importResults, setImportResults] = React.useState(null)
  const [triggerGetSurveyExcelfile, { data: getExcelFileData, isLoading: isLoadingGetExcel, error: errorExcel }] = useLazyGetSurveyExcelfileQuery()

  console.log('isLoadingGetExcel', isLoadingGetExcel)
  const navigate = useNavigate()
  const fileRef = useRef()
  const clearTable = () => {
    fileRef.current.value = ''
    setQuestions([])
    setModels([])
    setTable()
  }
  const handleImport = (e) => {
    const files = e.target.files
    if (files.length) {
      const file = files[0]
      setFile(file)
      const reader = new FileReader()
      reader.onload = (event) => {
        const wb = read(event.target.result)
        const sheets = wb.SheetNames
        if (sheets.length) {
          const modelsData = utils.sheet_to_json(wb.Sheets.ANSWER_MODEL)
          const questionsData = utils.sheet_to_json(wb.Sheets.QUESTIONS)
          const additionalParamsData = utils.sheet_to_json(wb.Sheets.ADDITIONAL_PARAMETERS)
          const surveyData = utils.sheet_to_json(wb.Sheets.SURVEY)
          const surveyTreeData = utils.sheet_to_json(wb.Sheets.SURVEY_TREE)
          const formulesGlobales = utils.sheet_to_json(wb.Sheets.GLOBAL_FORMULAS)
          const transformationMethod = utils.sheet_to_json(wb.Sheets.TRANSFORMATION_METHOD)
          const formules = utils.sheet_to_json(wb.Sheets.FORMULAS)
          setModels(modelsData)
          setQuestions(questionsData)
          setAdditionalParameters(additionalParamsData)
          setSurvey(surveyData)
          setSurveyTree(surveyTreeData)
          setGlobalformulas(formulesGlobales)
          setMethodesTransformation(transformationMethod)
          setFormule(formules)
          setTable('M')
        }
      }
      reader.readAsArrayBuffer(file)
    }
  }
  const handleGetSurveyExcelFile = async () => {
    try {
      await triggerGetSurveyExcelfile()
    } catch (error) {
      console.error('Error initiating evaluation report generation:', error)
    }
  }
  const submitImport = async (e) => {
    e.preventDefault()

    try {
      const response = await HandleSendFile(file, setLoadingImportFile)

      if (response instanceof Error) {
        console.error('Error occurred:', response)
        alert('admin.survey.import_export.error_occured')
      } else {
        setImportResults(response)
      }
    } catch (error) {
      console.error('Unexpected error occurred:', error)
      alert('admin.survey.import_export.unexpected_error_occured')
    }
  }
  React.useEffect(() => {
    if (importResults && file) {
      setModels([])
      setQuestions([])
      setAdditionalParameters([])
      setSurvey([])
      setSurveyTree([])
      setGlobalformulas([])
      setMethodesTransformation([])
      setFormule([])
      navigate('/admin/survey/confirm_import', { state: { file, reponse: importResults } })
    }
  }, [importResults])

  const { t } = useTranslation()
  return (
    <div className="w-full pb-16">
      <DynamicNavBar option="navbar.importOptions.import_export" options={importOptions} />
      <div className="w-full flex flex-col items-center gap-8  static mt-4" >
        <div className="sm:w-11/12 bg-wite md:mx-8 pb-8">
          <h5 className="text-2xl font-medium text-[#444444] w-max-fit">{t('admin.survey.import_export.importer')}</h5>
          <div className="mt-1 shadow-sm rounded-md w-full mx-2 bg-white px-4 py-4 flex flex-col gap-4">
            <div className="flex justify-between w-full">
              <div className="pl-5 pt-1 w-full flex justify-between">
                <div className="flex max-h-fit pt-1">
                  <label htmlFor="inputGroupFile"className="bg-teal-700 text-white px-1 min-w-fit flex float-right">{t('common.buttons.choose_file')}</label>
                  <div>
                    <span className="text-gray-700 ml-1">{file?.name || t('common.fields.choosen_file')}</span>
                  </div>
                  <input type="file" name="file" ref={fileRef} className="opacity-0 absolute"
                    id="inputGroupFile" required onChange={(e) => handleImport(e)}
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  />
                </div>
                <div className='flex gap-1'>
                  <div className='border-1 border-gray-300 rounded-md px-1 p-1 float-right max-w-fit flex gap-1' style={{ cursor: 'pointer' }} onClick={() => handleGetSurveyExcelFile()}>
                    <span>{t('admin.survey.import_export.Download')}</span>
                    {isLoadingGetExcel ? <Clock size={25}/> : <DownloadIcon style={{ fill: 'green' }} /> }
                  </div>
                  {questions.length !== 0 &&
                    <div className="border-1 border-gray-300 rounded-md px-1 p-1 float-right max-w-fit flex" style={{ cursor: 'pointer' }} onClick={() => clearTable()}>
                      <h5 className="text-md font-medium px-1 max-w-fit">{t('admin.survey.import_export.initilizeList')}</h5>
                      <ReplayIcon sx={{ color: '#4682B4' }} />
                    </div>}
                </div>
              </div>
            </div>
            <form onSubmit={(e) => submitImport(e)}>
              <div className='flex mb-1 gap-1'>
                <div className={`border-1 border-gray-300 rounded-md px-1 p-1 float-right max-w-fit ${table === 'M' ? 'bg-white' : 'bg-gray-200'}`}
                  style={{ cursor: 'pointer' }} onClick={() => setTable('M')}>
                  <span>{t('admin.survey.import_export.typeReponse')}</span>
                </div>
                <div className={`border-1 border-gray-300 rounded-md px-1 p-1 float-right max-w-fit ${table === 'Q' ? 'bg-white' : 'bg-gray-200'}`}
                  style={{ cursor: 'pointer' }} onClick={() => setTable('Q')}>
                  <span>{t('admin.survey.import_export.questions')}</span>
                </div>
                <div className={`border-1 border-gray-300 rounded-md px-1 p-1 float-right max-w-fit ${table === 'AP' ? 'bg-white' : 'bg-gray-200'}`}
                  style={{ cursor: 'pointer' }} onClick={() => setTable('AP')}>
                  <span>{t('admin.survey.import_export.addition_params')}</span>
                </div>
                <div className={`border-1 border-gray-300 rounded-md px-1 p-1 float-right max-w-fit ${table === 'S' ? 'bg-white' : 'bg-gray-200'}`}
                  style={{ cursor: 'pointer' }} onClick={() => setTable('S')}>
                  <span>{t('admin.survey.import_export.questionnaire')}</span>
                </div>
                <div className={`border-1 border-gray-300 rounded-md px-1 p-1 float-right max-w-fit ${table === 'ST' ? 'bg-white' : 'bg-gray-200'}`}
                  style={{ cursor: 'pointer' }} onClick={() => setTable('ST')}>
                  <span>{t('admin.survey.import_export.arbre')}</span>
                </div>
                <div className={`border-1 border-gray-300 rounded-md px-1 p-1 float-right max-w-fit ${table === 'TM' ? 'bg-white' : 'bg-gray-200'}`}
                  style={{ cursor: 'pointer' }} onClick={() => setTable('TM')}>
                  <span>{t('admin.survey.import_export.transformation_method')}</span>
                </div>
                <div className={`border-1 border-gray-300 rounded-md px-1 p-1 float-right max-w-fit ${table === 'GF' ? 'bg-white' : 'bg-gray-200'}`}
                  style={{ cursor: 'pointer' }} onClick={() => setTable('GF')}>
                  <span>{t('admin.survey.import_export.global_formulas')}</span>
                </div>
                <div className={`border-1 border-gray-300 rounded-md px-1 p-1 float-right max-w-fit ${table === 'F' ? 'bg-white' : 'bg-gray-200'}`}
                  style={{ cursor: 'pointer' }} onClick={() => setTable('F')}>
                  <span>{t('admin.survey.import_export.question_formulas')}</span>
                </div>
              </div>
              {table === 'M' && <ImportResponseModelTable data={models || undefined} />}
              {table === 'Q' && <ImportQuestionsTable data={questions || undefined} />}
              {table === 'AP' && <ImportAdditionalParamsTable data={additionalParameters || undefined} />}
              {table === 'S' && <ImportSurvey data={survey || undefined} />}
              {table === 'ST' && <ImportSurveyTree data={surveyTree || undefined} />}
              {table === 'GF' && <ImportGlobalFormulas data={globalformulas || undefined} />}
              {table === 'TM' && <ImportTransformationMethod data={methodesTransformation || undefined} />}
              {table === 'F' && <ImportFormulasList data={formule || undefined} />}
              <div className='flex justify-end w-full'>
              <button
                className="flex gap-1 bg-teal-700 text-white px-2 py-1 items-center "
                style={{ cursor: 'pointer' }} type="submit" disabled={loadingImportFile}>
                 {loadingImportFile && <Clock size={25}/>}
                <p>{t('admin.survey.import_export.importer')}</p>
              </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ImportQuestion

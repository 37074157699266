import { useEffect, useMemo, useRef, useState } from "react";
import * as d3 from "d3";
import { bool } from "prop-types";

const MARGIN = { top: 30, right: 30, bottom: 80, left: 100 };
const BUBBLE_MIN_SIZE = 5;
const BUBBLE_MAX_SIZE = 25;

type BubblePlotProps = {
  width: number;
  height: number;
  data: {
    internal_risk: number;
    internal_financial_impact: number;
    internal_environmental_impact: number;
    external_risk: number;
    external_financial_impact: number;
    external_environmental_impact: number;
    probability: number;
    alignement: number;
    parent_text: string;
  }[];
};

export const BubblePlot = ({ width, height, data, params}: BubblePlotProps) => {
  const [displayedLabel, setDisplayedLabel] = useState(""); // State to keep track of the label to display
  let onMouseOver = (event: any, d: any) => {
    setDisplayedLabel(d.label);
    
  }
  // Layout. The div size is set by the given props.
  // The bounds (=area inside the axis) is calculated by substracting the margins
  const axesRef = useRef(null);
  const boundsWidth = width - MARGIN.right - MARGIN.left;
  const boundsHeight = height - MARGIN.top - MARGIN.bottom;
  let axisOriginOffset = 20


  // Scales
  const yScale = useMemo(() => {
    const [min, max] = [0,20]
    return d3.scaleLinear().domain([min, max]).range([boundsHeight, 0]).nice();
  }, [data, height]);

  const xScale = useMemo(() => {
    const [min, max] = [0, 20]/*d3.extent(data.map((d) => d.x_risk)) as [
      number,
      number
    ];*/
    return d3.scaleLinear().domain([0, max]).range([0, boundsWidth]).nice();
  }, [data, width]);
  const groups = 
    data
  .map((d) => d.parent_text)
  .filter((x, i, a) => a.indexOf(x) == i)



  const defaultColorPanel =  ['#C80000', '#FF5F5F', '#FFEFA1', '#7CE182', '#089C11']
  

  const defaultColorScale = d3
  .scaleOrdinal<string>()
  .domain(groups)
  .range(params?.colors?.color_panel || defaultColorPanel );

  const alignementColorScale = d3
    .scaleLinear()
    .domain([0, 4, 8, 12, 16, 20])  
    .range(params?.colors?.color_panel || defaultColorPanel );

  const probabilityColorScale = d3
    .scaleLinear()
    .domain([0, 0.2, 0.4, 0.6, 0.8, 1])  
    .range(params?.colors?.color_panel || defaultColorPanel );

    const setColorScale = (d, filter_name) => {
      // Mapping of filter names to corresponding scale functions
      const scaleMapping = {
        probability: () => probabilityColorScale(d.probability),
        default: () => defaultColorScale(d.parent_text),
        alignement: () => alignementColorScale(d.alignement),
      };
    
      // Get the corresponding scale function based on the filter name
      const scaleFunction = scaleMapping[filter_name];
    
      // If the scale function exists, invoke it; otherwise, log a warning and return a default value
      if (scaleFunction) {
        return scaleFunction();
      } else {
        console.warn("Unknown filter_name:", filter_name);
        return defaultColorScale(d.parent_text); // Default fallback
      }
    };

    const defaultScale = d3
      .scaleSqrt()
      .domain([0, 20])
      .range([BUBBLE_MIN_SIZE, BUBBLE_MAX_SIZE]);

    const setSizeScale = (d, filter_name) => {
        // Extract the value based on the filter_name
        const value = d[filter_name];
      
        if (value != null) {
          // If the value exists, apply the size scale
          return sizeScale(value);
        } else {
          // Log a warning if the value is not found
          console.warn("Unknown or missing value for filter_name:", filter_name);
          return BUBBLE_MIN_SIZE; // Fallback to the minimum size
        }
      };
      

    const sizeScale = useMemo(() => {
      // Calculate the extent for the specified size filter
      const [min, max] = d3.extent(
        data.map((d) =>
          d[`${params?.size?.axis_box_1?.filter_name}_${params?.size?.axis_box_2?.filter_name}`]
        )
      ) || [0, 20]; // Fallback to [0, 20] if extent fails
    
      // Create a square root scale for size mapping
      return d3
        .scaleSqrt()
        .domain([min || 0, max || 20]) // Default to [0, 20] for missing data
        .range([BUBBLE_MIN_SIZE, BUBBLE_MAX_SIZE]);
    }, [data, params?.size?.axis_box_1?.filter_name, params?.size?.axis_box_2?.filter_name, width]);
    

  // Render the X and Y axis using d3.js, not react
  useEffect(() => {
    const svgElement = d3.select(axesRef.current);
    svgElement.selectAll("*").remove();

    const xAxisGenerator = d3.axisBottom(xScale);
    svgElement
      .append("g")
      .attr("transform", "translate(0," + (boundsHeight + axisOriginOffset) + ")")
      .call(xAxisGenerator);
    svgElement
      .append("text")
      .attr("x", boundsWidth / 2)
      .attr("y", boundsHeight + MARGIN.top + 33) // Adjust the y position
      .attr("dx", "10px")
      .attr("text-anchor", "middle")
      .attr("font-size", 20)
      .text("Enjeux internes");

    const yAxisGenerator = d3.axisLeft(yScale);
    svgElement
      .append("g")
      .attr("transform", "translate(" + -axisOriginOffset + ",0)")
      .call(yAxisGenerator);
    svgElement
      .append("text")
      .attr("transform", "rotate(-90)")
      .attr("x", 0 - boundsHeight / 2)
      .attr("y", -MARGIN.left+ 10)
      .attr("dy", "10px")
      .attr("text-anchor", "middle")
      .attr("font-size", 20)
      .text("Enjeux externes pour vos parties prenantes ")
      .attr("transform", "rotate(-90)");
  }, [xScale, yScale, boundsHeight, boundsWidth]);

  // Build the shapes
  const allShapes = data
    .sort((a, b) => b.impact - a.impact)
    .map((d, i) => {
      
      return (
        <circle
          key={i}
          r={setSizeScale(d, `${params?.x_param?.axis_box_1?.filter_name}_${params?.x_param?.axis_box_2?.filter_name}`)}
          cx={xScale(d[`${params?.x_param?.axis_box_1?.filter_name}_${params?.x_param?.axis_box_2?.filter_name}`])}
          cy={yScale(d[`${params?.y_param?.axis_box_1?.filter_name}_${params?.y_param?.axis_box_2?.filter_name}`])}
          opacity={1}
          stroke={setColorScale(d, params?.colors?.filter_name)}
          fill={setColorScale(d, params?.colors?.filter_name)}
          fillOpacity={0.4}
          strokeWidth={1}
          onMouseOver={(event) => onMouseOver(event, d)}
        />
      );
    });

  return (
    <div>
      <svg width={width} height={height}>
        <g
          width={boundsWidth}
          height={boundsHeight}
          transform={`translate(${[MARGIN.left, MARGIN.top].join(",")})`}
        >
          {allShapes}
        </g>
        <g
          width={boundsWidth}
          height={boundsHeight}
          ref={axesRef}
          transform={`translate(${[MARGIN.left, MARGIN.top].join(",")})`}
        />
      </svg>
      <div>{displayedLabel}</div>
    </div>
  );
};

import React from 'react'
import { Accordion, AccordionTab } from 'primereact/accordion'
import AddIcon from '@mui/icons-material/Add'
import CloseIcon from '@mui/icons-material/Close'
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'

const SurveyAccordion = ({ questionsList, data, handlecreate, handlemodify, setChosedNode }) => {
  const renderQuestions = (questions) => {
    return questions?.map((q) => {
      const questionFound = questionsList?.find((question) => question.id === q)
      return (
        <div className="flex gap-1 text-black truncate ..." key={q.question_id}>
          <HelpOutlineIcon style={{ fill: '#188B15', fontSize: 20 }} />
          <p>R {questionFound.reference} / </p>
          <p>T {questionFound.question_type} / </p>
          <p>Q {questionFound.text}</p>

        </div>
      )
    })
  }

  const renderCategories = (categories) => {
    return categories.map((category) => {
      const subcategories = category.categories.map((subcategoryId) =>
        data?.find((node) => node.id === subcategoryId)
      )
      return (
        <AccordionTab key={category.id} headerTemplate={<CategoryHeader category={category} />}>
          {category.question.length > 0 && renderQuestions(category?.question)}

          {subcategories.length > 0 && (
            <Accordion>
              {renderCategories(subcategories)}
            </Accordion>
          )}
        </AccordionTab>
      )
    })
  }

  const CategoryHeader = ({ category }) => {
    return (
      <div className="flex w-full justify-between" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <span className='text-lg '>{category?.title}</span>
        <div className='flex flex-col'>
        <span className='flex w-full justify-between'>
          <div className='flex text-md pt-1 text-gray-600 px-2 align-bottom'>
            <p>{category?.categories?.length || 0} N  /  {category?.question?.length || 0} Q</p>
          </div>
        </span>
        <div className="flex float-right gap-1">
          <AddIcon style={{ fill: '#188B15', fontSize: 18 }} onClick={(e) => { e.stopPropagation(); handlecreate(category?.id || null) }} />
          <AutoFixHighIcon style={{ fill: '#f7b26d', fontSize: 18 }} onClick={(e) => { e.stopPropagation(); handlemodify(category?.id || null) }} />
          <CloseIcon style={{ fill: '#C70039', fontSize: 18 }} onClick={(e) => { e.stopPropagation(); setChosedNode({ node: category?.id, action: 'delete' }) }} />
        </div>
        </div>
        { /* des image de chaque campagne sera possible <img src={`path/to/logo/${category.id}.png`} alt="logo" style={{ width: '20px', height: '20px' }} /> */}
      </div>
    )
  }

  const root = data.find((node) => node.node_type === 'root')
  const categories = data.filter((node) => node.node_type === 'category' && node.parentId === root.id)

  return (
    <div>
      <div className='flex justify-between'>
        <h1 className='text-xl pb-3'>{root.title}</h1>
        <AddIcon className="border-2 rounded-md" style={{ fill: '#188B15', fontSize: 23 }} onClick={(e) => { e.stopPropagation(); handlecreate(root.id) }} />
      </div>
      <Accordion>
        {renderCategories(categories)}
      </Accordion>
    </div>
  )
}

export default SurveyAccordion

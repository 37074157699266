import React from 'react'
import IndexHub from './layouts/hub'
import '@valueandco/vcdesign/packages/datatable-library/src/utils/configure'
import IndexSurvey from './layouts/survey/indexSurvey'
import IndexAdmin from './layouts/admin/indexAdmin'
import IndexAuth from './layouts/auth/indexAuth'
import RequireAuth from './layouts/auth/RequireAuth'
import Unauthorized from './layouts/auth/unauthorized'
import NotFound from './layouts/auth/notFound'
import RedirectAuth from './layouts/auth/RedirectAuth'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import EntityFile from './features/entities/entityFile'
import SessionManager from './features/hooks/sessionManager'
import 'tw-elements'

function App () {
  return (
        <BrowserRouter>
            <Routes>
                {/* Protected routes from  unauthorized access */}
                <Route element={<SessionManager/>} >
                    <Route element={<RequireAuth allowedRole={['CLIENT', 'ADMIN', 'SUPERADMIN']} />}>
                        <Route path="entity_file" element={<EntityFile />}></Route>
                        <Route path="hub/*" exact element={<IndexHub />} />
                    </Route>
                    <Route path="survey/*" exact element={<IndexSurvey />} />
                    <Route element={<RequireAuth allowedRole={['ADMIN', 'SUPERADMIN']} />}>
                        <Route path="admin/*" exact element={<IndexAdmin />} />
                    </Route>
                    <Route exact path="/" element={<RedirectAuth />}>
                    </Route>
                </Route>
                <Route exact element={<RedirectAuth />}>
                </Route>
                <Route path="auth/*" exact element={<IndexAuth />} />
                {/*  show unauthorized page for pages */}
                <Route path="unauthorized" exact element={<Unauthorized />} />
                {/* Protected routes with require Auth */}
                {/* Show not found page */}
                <Route path="*" element={<NotFound />} />
            </Routes>
        </BrowserRouter>
  )
}
export default App

import React from 'react'
import { useCreateQuestionMutation, useGetQuestionTagsQuery, useGetOptionsLGQuery, useGetOptionsTemplatesQuery } from './questionSlice'
import IndexAlert from '../../components/alerts/indexAlert'
import { Spinner } from '../../components/loadData/spinner'
import AddIcon from '@mui/icons-material/Add'
import { useTranslation } from 'react-i18next'
import AdditionalParams from './components/additionalParams'
import { MultiSelect } from 'primereact/multiselect'
import Input from './utils/input'

const initialQuestion = { text: '', question_type: '', select_options: [], question_tag: [], reference: '', options_model: '', addParams: false, unit: '', question_help_text: '', params: { denominator: '', min: '', max: '' } }

export default function QuestionAdd ({ setOption, option }) {
  const { t } = useTranslation()

  const [createQuestion, { isLoading, isSuccess, isError, error }] = useCreateQuestionMutation()
  const { data: questionTags } = useGetQuestionTagsQuery()
  const { data: questionTemplates } = useGetOptionsTemplatesQuery()
  const { data: options } = useGetOptionsLGQuery()
  const [optionFilter, setOptionFilter] = React.useState(options)
  const [questionTagsFilter, setQuestionTagsFilter] = React.useState(questionTags)
  const [questionTemplatesFilter, setQuestionTemplatesFilter] = React.useState(questionTemplates)
  const [question, setQuestion] = React.useState(initialQuestion)
  const FilterList = (text, method, list) => {
    if (text === '') {
      method(list)
    } else {
      method(list.filter((l) => l.text.toUpperCase().includes(text.toUpperCase())))
    }
  }
  React.useEffect(() => {
    setOptionFilter(options)
    setQuestionTagsFilter(questionTags)
    setQuestionTemplatesFilter(questionTemplates)
  }, [questionTags, questionTemplates, options])

  const questionType = ['', 'select', 'number', 'boolean', 'multiselect', 'slider', 'text']

  const handleCreateQuestion = async () => {
    const tags = question.question_tag.map((tag) => tag.id)
    await createQuestion({ ...question, question_tag: tags })
    setQuestion(initialQuestion)
  }
  return (
    <div className="w-full">
      <div className="md:w-full px-0.5 mb-6 md:mb-0 relative rounded-sm ">
        {isSuccess && <IndexAlert type="success" text={t('question.question_created')} />}
        {isLoading && <Spinner />}
        {isError && <div><IndexAlert type="danger" text={JSON.stringify(error.data.error)} /> </div>}
        <h5 className="text-2xl font-medium text-gray-700 w-max-fit">{t('admin.survey.questions.question_create')}</h5>
        <label htmlFor="price" className="block font-medium text-gray-700 text-lg">
          {t('admin.survey.questions.question_text')}
        </label>
        <textarea type="text" name="price" id="price" className="focus:ring-teal-500
        focus:border-teal-500 block  w-full pl-2 pr-12 text-lg  text-[#444444]
        rounded-sm" style={{ border: '1px solid #e8e6e6' }} rows={7}
          placeholder="Question text" value={question?.text ? question.text : ''}
          onChange={(e) => setQuestion({ ...question, text: e.target.value })}></textarea>
        <label htmlFor="price" className="block font-medium text-gray-700 text-lg">
          {t('admin.survey.questions.question_help_text')}
        </label>
        <textarea type="text" name="price" id="price" className="focus:ring-teal-500
        focus:border-teal-500 block  w-full pl-2 pr-12 text-lg  text-[#444444]
        rounded-sm" rows = {4} style={{ border: '1px solid #e8e6e6' }} placeholder={t('admin.survey.questions.question_help_text')}
        value={question?.question_help_text ? question.question_help_text : ''}
        onChange={(e) => setQuestion({ ...question, question_help_text: e.target.value })}></textarea>
      </div>
      <div className="md:w-full mb-6 md:mb-0 mt-2 mb-1 relative rounded-sm ">
        <label htmlFor="price" className="block font-medium text-gray-700 text-lg">
          {t('admin.survey.questions.question_type')}
        </label>
        <select className="w-1/2 focus:ring-teal-500 focus:border-teal-500 block border-2  border-[#e8e6e6] h-8 w-full pl-1 pr-2 text-lg  text-[#444444]
          rounded-sm" value={question?.question_type ? question.question_type : ''}
          onChange={(e) => setQuestion({ ...question, question_type: e.target.value, unit: e.target.value === 'slider' ? 'Fraction' : question.unit })}>
          {questionType.map((qst) => <option value={qst} key={qst}>{qst}</option>)}
        </select>

        <Input text ={t('admin.survey.questions.question_referance')} value={question?.reference || ''}
        onChange={(val) => setQuestion({ ...question, reference: val })}
        type={'text'} disabled={false}/>
        <AdditionalParams question={question || null} ChangeQuestion={(val) => setQuestion(val)}/>
        {['select', 'multiselect', 'slider'].includes(question.question_type) &&
          <div className="mt-2 mb-1 w-full flex gap-4">
            <div className='w-full'>
              <label htmlFor="price" className="block font-medium text-gray-700 text-lg">
                {t('question.respond_options')}
              </label>
              <div className="w-full flex jusify-between gap-2">
                <div className="flex flex-col jusify-between gap-2 w-full">
                  <input onChange={(e) => FilterList(e.target.value, setOptionFilter, options)} className="border-1 border-gray-300  w-1/2" />
                  <div className='flex'>
                    <select className="w-full focus:ring-teal-500 focus:border-teal-500 block border-2
       border-[#e8e6e6] pl-1 pr-2 text-lg  text-[#444444] max-w-100
          rounded-sm"
                      value={question?.select_options ? question.select_options : []}
                      onChange={(e) => setQuestion({ ...question, select_options: Array.from(e.target.selectedOptions, option => option.value), options_model: '' })} multiple={true}>
                      {optionFilter ? optionFilter.map((op) => <option value={op.id}>{op.text}</option>) : undefined}
                    </select>
                    <div className='rounded-full bg-teal-700 max-h-fit align-middle' style={{ cursor: 'pointer' }}>
                      <AddIcon onClick={() => setOption({ option: option.option != 'option' ? 'option' : '' })} style={{ fill: '#fff' }} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full">
              <label htmlFor="price" className="block font-medium text-gray-700 text-lg">
                {t('question.respond_model')}
              </label>
              <div className="flex jusify-between gap-2">
                <select className="w-full focus:ring-teal-500 focus:border-teal-500 block border-2
       border-[#e8e6e6] pl-1 pr-2 text-lg  text-[#444444] max-w-100
          rounded-sm"
                  value={question?.options_model ? question.options_model : ''}
                  onChange={(e) => setQuestion({ ...question, select_options: [], options_model: e.target.value })} >
                  <option></option>
                  {questionTemplates ? questionTemplates.map((op) => <option value={op.id} key={op.id}>{op.title}</option>) : undefined}
                </select>

                <div className='rounded-full bg-teal-700 max-h-fit align-middle' style={{ cursor: 'pointer' }}>
                  <AddIcon onClick={() => setOption({ option: option.option !== 'model' ? 'model' : '' })} style={{ fill: '#fff' }} />
                </div>
              </div>
            </div>
          </div>
        }
        <div className='flex flex-col'>
          <label htmlFor="price" className="mt-2 mb-1 block font-medium text-gray-700 text-lg">
            {t('admin.survey.questions.question_tags')}
          </label>
          <div className="card flex md:w-3/4 sm:w-1/2">
            <MultiSelect value={question?.question_tag || []} onChange={(e) => setQuestion({ ...question, question_tag: e.value })} options={questionTagsFilter || []}
            optionLabel="text" display="chip" filter placeholder={t('admin.survey.questions.question_tags')} maxSelectedLabels={15} className="w-full rounded-sm" />
        </div>
        </div>
        <div className="flex justify-center items-center w-max-fit rounded-sm bg-teal-700 text-lg text-white
        px-2 py-0.5 mt-4 float-right" style={{ cursor: 'pointer' }} onClick={() => handleCreateQuestion()}>{t('common.buttons.create')}</div>
      </div>
    </div>
  )
}

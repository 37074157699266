import { apiSlice } from '../api/apiSlice'
import { clientId, secretKey } from '../../var.conf'

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    login: builder.mutation({
      query: LoginInfo => ({
        url: '/o/token/',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: {
          username: LoginInfo.username,
          password: LoginInfo.password,
          grant_type: 'password',
          client_id: clientId,
          client_secret: secretKey
        }
      })
    }),
    getUserCredentials: builder.mutation({
      query: (token) => ({
        url: '/user/credentials',
        method: 'POST',
        headers: { authorization: `bearer ${token}` }
      })
    }),
    requestResetPassword: builder.mutation({
      query: (data) => ({
        url: '/auth/users/reset_password/',
        method: 'POST',
        body: data
      })
    }),
    changePassword: builder.mutation({
      query: (data) => ({
        url: '/auth/users/reset_password_confirm/',
        method: 'POST',
        body: data
      })
    }),
    verifytoken: builder.query({
      query: (token) => ({
        url: '/verify_token/',
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
    })
  })
})
export const {
  useLoginMutation,
  useGetUserCredentialsMutation,
  useRequestResetPasswordMutation,
  useChangePasswordMutation,
  useVerifytokenQuery
} = authApiSlice

import React, { useMemo } from 'react'
import { Accordion, AccordionTab } from 'primereact/accordion'
import LinkIcon from '@mui/icons-material/Link'
import CreateIcon from '@mui/icons-material/Create'
import CloseIcon from '@mui/icons-material/Close'
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'


const EvaluationMethodAccordion = ({ surveyTree, handlemodify, handlecreate, handlelink, setChosedNode, handledelete }) => {
  const { nodes, links } = surveyTree.trees[0]

  // Build a map of nodes by uuid for quick lookup
  const nodeMap = useMemo(() => {
    const map = {}
    nodes.forEach(node => {
      if (['category', 'main', 'link', 'static'].includes(node.type)) {
        map[node.uuid] = { ...node, children: [], questions: [] }
      }
    })
    return map
  }, [nodes])

  // Link nodes to their children based on links
  useMemo(() => {
    links.forEach(link => {
      const parent = nodeMap[link.parent]
      const child = nodeMap[link.child]
      if (parent && child && ['category', 'link'].includes(child.type)) {
        if (!parent.children.includes(child.uuid)) { // make sure that there is a unique child
          parent.children.push(child.uuid)
        }
      }
      if (parent && child && ['static'].includes(child.type)) {
        if (!parent.questions.includes(child.uuid)) { // make sure that there is a unique child
          parent.questions.push(child.uuid)
        }
      }
    })
  }, [links, nodeMap])

  // Render questions
  const renderQuestions = (questionIds) => {
    return questionIds?.map((qId) => {
      const question = nodeMap[qId]
      if (question?.type === 'static') {
        return (
          <div className='flex' key={qId}>
            <div className="flex gap-1 text-black truncate">
              <HelpOutlineIcon style={{ fill: '#188B15', fontSize: 20 }} />
              <p>R {question.reference} / </p>
              <p>Q {question.text}</p>
            </div>
            <div className="flex float-right gap-1">
              <AutoFixHighIcon style={{ fill: '#f7b26d', fontSize: 18 }} onClick={(e) => { e.stopPropagation(); handlemodify(question.uuid) }} />
              <CloseIcon style={{ fill: '#C70039', fontSize: 18 }} onClick={(e) => { e.stopPropagation(); handledelete(question.uuid) }} />
            </div>
          </div>
        )
      }
      return null
    })
  }

  // Render categories
  const renderCategories = (categoryIds, renderedCategories = new Set()) => {
    return categoryIds.map((categoryId) => {
      const category = nodeMap[categoryId]
      if (!['category', 'main', 'link'].includes(category?.type) || renderedCategories.has(categoryId)) {
        return null
      } else {
        renderedCategories.add(categoryId) // Add category to rendered set
        return (
          <AccordionTab key={category.uuid} headerTemplate={<CategoryHeader category={category} />}>
            {category.children.length > 0 && (
              <Accordion>
                {renderCategories(category.children, renderedCategories)}
              </Accordion>
            )}
            {category.questions.length > 0 && renderQuestions(category?.questions)}
          </AccordionTab>
        )
      }
    })
  }

  const CategoryHeader = ({ category }) => {
    return (
      <div className="flex w-full justify-between" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        {category.type === 'main'
          ? <span className='text-lg font-bold'>{surveyTree?.name}</span>
          : <span className='text-lg'>{category.text}</span>}

        <div className='flex flex-col'>
          <span className='flex w-full justify-between'>
            <div className='flex gap-3 text-md pt-1 text-gray-600 px-2 align-bottom'>
              <p>{category.children.length} N</p>
              <p>{category.questions.length} Q</p>
              {category?.type === 'link' && <p className="text-[#188B15]">Link</p>}
            </div>
          </span>
          <div className="flex float-right gap-1">
            <CreateIcon style={{ fill: '#188B15', fontSize: 18 }} onClick={(e) => { e.stopPropagation(); handlecreate(category.uuid) }} />
            <LinkIcon style={{ fill: '#188B15', fontSize: 18 }} onClick={(e) => { e.stopPropagation(); handlelink(category.uuid) }} />
            {category.type !== 'main' && <AutoFixHighIcon style={{ fill: '#f7b26d', fontSize: 18 }} onClick={(e) => { e.stopPropagation(); handlemodify(category.uuid) }} />}
            <CloseIcon style={{ fill: '#C70039', fontSize: 18 }} onClick={(e) => { e.stopPropagation(); handledelete(category.uuid) }} />
          </div>
        </div>
      </div>
    )
  }

  // Find the root nodes (nodes with no parent in links)
  const rootNodeIds = nodes?.filter((node) => node.type === 'main').map(node => node.uuid)
  return (
    <div>
      <Accordion>
        {renderCategories(rootNodeIds)}
      </Accordion>
    </div>
  )
}

export default EvaluationMethodAccordion

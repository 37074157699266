import axios from 'axios'
import { baseURL } from '../../var.conf.js'

export const axiosDownloadDocument = async (id, fileName) => {
  try {
    const response = await axios({
      url: `${baseURL}/documents/download/${id}`,
      method: 'GET',
      responseType: 'blob', // Use 'blob' for binary data
    })

    // Create a Blob from the received data
    const blob = new Blob([response.data], { type: response.headers['content-type'] })

    // Create a download link
    const url = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', fileName) // Use the provided file name

    // Append the link to the document and trigger the download
    document.body.appendChild(link)
    link.click()

    // Cleanup: remove the link after the download
    document.body.removeChild(link)
    window.URL.revokeObjectURL(url) // Clean up the object URL
  } catch (error) {
    console.error('Error downloading file:', error)
  }
}
import React, { useState, useEffect } from 'react'
import { Tree } from 'primereact/tree'
import ClassIcon from '@mui/icons-material/Class'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import { getFormatAccordionData } from '../components/prepareOutoutResult'
import { useTranslation } from 'react-i18next'
import CommentIcon from '@mui/icons-material/Comment'
import LinkIcon from '@mui/icons-material/Link'

const NestedAccordion = ({ comments, evaluation, isLoading, isSuccess, questionComments }) => {
  const { t } = useTranslation()
  const [evaluationTree, setEvaluationTree] = useState()
  const [showComment, setShowComment] = useState()
  const [expandedKeys, setExpandedKeys] = useState({ 0: true, '0-0': true })

  const getComments = (id) => {
    if (comments) {
      return comments.filter((comment) => comment.origin_question === id)
    } else {
      return null
    }
  }

  const countComments = (id) => {
    if (comments) {
      const commentsSize = comments.filter((comment) => comment.origin_question === id)
      return commentsSize.length
    } else {
      return null
    }
  }

  useEffect(() => {
    if (evaluation) {
      setEvaluationTree(getFormatAccordionData(evaluation))
    }
  }, [evaluation, questionComments])

  const filterNodes = (nodes) => {
    return nodes
      .map((node) => {
        if (node.type === 'static') {
          const nodeComments = getComments(node.key)
          if (questionComments && (!nodeComments || nodeComments.length === 0)) {
            return null // Exclude nodes without comments when questionComments is true
          }
        }
        if (node.children) {
          node.children = filterNodes(node.children)
        }
        return node
      })
      .filter((node) => node !== null)
  }

  const nodeTemplate = (node, options) => {
    let label
    const nodeComments = getComments(node.key)

    if (['category', 'main', 'link'].includes(node.type)) {
      label = (
        <div className="flex gap-3">
          <div className="font-semibold flex gap-1">
            {['category', 'main'].includes(node.type) && (
              <ClassIcon style={{ fill: '#F1EFC0', width: '20px', height: '20px' }} />
            )}
            {['link'].includes(node.type) && (
              <LinkIcon style={{ fill: '#188B15', width: '20px', height: '20px' }} />
            )}
            <h5 className="text-gray-700">{node.name}</h5>
          </div>
          {node.rawValue && (
            <div className="border border-grey-100 px-1 border-radius-1">{node.rawValue}</div>
          )}
        </div>
      )
    }

    if (node.type === 'static') {
      label = (
        <div className="flex flex-col">
          <div className="flex">
            <div>
              <div className="flex gap-1">
                <HelpOutlineIcon style={{ fill: '#72C17C', width: '20px', height: '20px' }} />
                <h5 className="text-gray-700">{node.reference}</h5>
              </div>
              <h5>{node.text}</h5>
            </div>
            <div
              className="relative inline-flex w-fit"
              onClick={() => {
                if (showComment === node?.key) {
                  setShowComment(null)
                } else {
                  setShowComment(node?.key)
                }
              }}
            >
              <div
                className="absolute bottom-auto left-auto right-0 top-0 z-10 inline-block -translate-y-1/2 translate-x-2/4 rotate-0 skew-x-0
                skew-y-0 scale-x-100 scale-y-100 whitespace-nowrap rounded-full bg-[#de8c8c] px-1.5 py-1 text-center align-baseline text-xs
                font-bold leading-none text-white"
              >
                {!nodeComments
                  ? 0
                  : nodeComments[0]?.origin_question === node?.key && countComments(node?.key)
                    ? countComments(node?.key)
                    : 0 }
              </div>
              <CommentIcon
                style={{ cursor: 'pointer', fill: '#72C17C', width: '20px', height: '20px' }}
              />
            </div>
          </div>
          <div>
            {nodeComments &&
              showComment === node?.key &&
              nodeComments.map((comment, index) => (
                <div className="px-3 mr-4" key={index}>
                  <p className="text-xs pl-4 text-gray-500 ">
                    {comment?.updated_at ? comment.updated_at : ''}
                  </p>
                  <div className="rounded-md border border-gray-200 px-3 mr-4 bg-gray-100 ml-10  shadow-sm hover:shadow-md transition duration-300 w-full">
                    <div>
                      <p className="text-md text-gray-800">
                        {comment?.comment_text ? comment.comment_text : null}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      )
    }

    return <span>{label}</span>
  }

  const expandAll = () => {
    const _expandedKeys = {}
    for (const node of [evaluationTree]) {
      expandNode(node, _expandedKeys)
    }

    setExpandedKeys(_expandedKeys)
  }

  const collapseAll = () => {
    setExpandedKeys({})
  }

  const expandNode = (node, _expandedKeys) => {
    if (node.children && node.children.length) {
      _expandedKeys[node.key] = true

      for (const child of node.children) {
        expandNode(child, _expandedKeys)
      }
    }
  }

  if (evaluationTree) {
    const filteredTree = questionComments ? filterNodes([evaluationTree]) : [evaluationTree]

    return (
      <div className="flex flex-col justify-content-center">
        <div className="flex flex-wrap gap-2 mb-4">
          <div
            style={{ cursor: 'pointer' }}
            className="bg-teal-600 mx-2 px-2 py-0.5 rounded-md mb-1 text-gray-100 text-md font-medium float-right p-1"
            onClick={expandAll}
          >
            {t('common.nestedAccordion.expand')}
          </div>
          <div
            style={{ cursor: 'pointer' }}
            className="bg-teal-600 mx-2 px-2 py-0.5 rounded-md mb-1 text-gray-100 text-md font-medium float-right p-1"
            onClick={collapseAll}
          >
            {t('common.nestedAccordion.collapse')}
          </div>
        </div>
        <Tree
          expandedKeys={expandedKeys}
          onToggle={(e) => setExpandedKeys(e.value)}
          value={filteredTree}
          nodeTemplate={nodeTemplate}
          className="w-full"
        />
      </div>
    )
  }

  return null
}

export default NestedAccordion

import React, { useState } from 'react'
import Popup from 'reactjs-popup'
import 'reactjs-popup/dist/index.css'
import './style.css'
import { useTranslation } from 'react-i18next'

const CreateUser = ({ members, onChangeMembers, children, entity }) => {
  const { t } = useTranslation()
  const ref = React.useRef()
  const closeTooltip = () => ref.current.close()
  const [user, setUser] = useState()

  const handleAddUser = (e) => {
    e.preventDefault()

    if (user?.first_name && user?.last_name && user?.email && user?.post) {
      if (!members.find((filterUser) => user.email === filterUser.email)) {
        onChangeMembers({ ...entity, users: [...entity.users, { ...user, id: members.length }] })
        setUser({})
        closeTooltip()
      } else { alert(t('userExists')) }
    }
  }

  return (
        <div >
            <Popup
                closeOnDocumentClick={false}
                trigger={children}
                modal
                contentStyle={{ width: '340px', heigh: '250px' }}
                ref={ref}>
                {close => (
                    <div className="modal">
                        <button className="close" onClick={() => close()}>
                            &times;
                        </button>
                        <div className="content flex flex-col justify-center items-center ">
                            <h5 className="text-teal-600 text-2xl ">{t('common.popup.add_member')}</h5>
                           <form onSubmit={handleAddUser}>
                                <div className="mt-2">
                                    <div className="md:w-300 px-3 mt-1 relative rounded-sm ">
                                        <label htmlFor="price" className="block text-lg font-medium text-gray-700">
                                            {t('components.tables.entity.member_list.last_name')}
                                        </label>
                                        <input type="text" name="last_name" id="last_name" className="focus:ring-teal-500
                    focus:border-teal-500 block  w-full text-lg  text-[#444444] pl-1
                    rounded-sm border-1 border-gray-300" value={user?.last_name ? user.last_name : ''} onChange={(e) => setUser({ ...user, last_name: e.target.value })} />
                                        <label htmlFor="last_name" className="block text-lg font-medium text-gray-700">
                                            {t('common.popup.first_name')}
                                        </label>
                                        <input type="text" name="first_name" id="first_name" className="focus:ring-teal-500
                    focus:border-teal-500 block  w-full text-lg  text-[#444444] pl-1
                    rounded-sm border-1 border-gray-300" value={user?.first_name ? user.first_name : ''} onChange={(e) => setUser({ ...user, first_name: e.target.value })} />
                                        <label htmlFor="price" className="block text-lg font-medium text-gray-700">
                                        {t('components.tables.entity.member_list.email_address')}
                                        </label>
                                        <input type="email" name="price" id="price" className="focus:ring-teal-500
                    focus:border-teal-500 block  w-full text-lg  text-[#444444] pl-1
                    rounded-sm border-1 border-gray-300" value={user?.email ? user.email : ''} onChange={(e) => setUser({ ...user, email: e.target.value })} />

                                        <label htmlFor="price" className="block text-lg font-medium text-gray-700">
                                            {t('components.tables.entity.member_list.position')}
                                        </label>
                                        <input type="text" name="price" id="price" className="focus:ring-teal-500
                        focus:border-teal-500 block  w-full text-lg  text-[#444444] pl-1
                        rounded-sm border-1 border-gray-300" value={user?.post ? user.post : ''} onChange={(e) => setUser({ ...user, post: e.target.value })} />
                                       <label htmlFor="type" className="block text-lg font-medium text-gray-700">
                                            {t('components.tables.entity.member_list.type')}
                                        </label>
                                        <select name="type" id="type" className="focus:ring-teal-500
                        focus:border-teal-500 block  w-full text-lg  text-[#444444] pl-1 max-w-fit
                        rounded-sm border-1 border-gray-300" value={user?.type ? user.type : ''} onChange={(e) => setUser({ ...user, type: e.target.value })}>
                                            <option value=''></option>
                                            <option value="internal">{t('common.internal')}</option>
                                            <option value="external">{t('common.external')}</option>
                                          </select>
                                    </div>
                                </div>
                                <div className="actions mt-2 float-right">
                                    <input className="bg-teal-600 px-2 py-0.5 rounded-md mb-1 text-gray-100 text-md  float-right p-1" type="submit" style={{ cursor: 'pointer' }} value={t('common.button.validate')} />
                                </div>
                            </form>
                        </div>
                    </div>
                )}
            </Popup>
        </div>
  )
}
export default CreateUser

import React from 'react'
import { FilterMatchMode } from 'primereact/api'
import FolderIcon from '@mui/icons-material/Folder';
import EditIcon from '@mui/icons-material/Edit'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteEntity from '../../../../features/entities/components/deleteEntity'
import { template2 } from '../../../../appComponents/datatable/utils/paginators'
import { createDataTable } from '../../../../appComponents/datatable/data-table-factory'
import { renderHeader } from '../../../../appComponents/datatable/utils/filter'

import PropTypes from 'prop-types';

function EntityListTable ({ entities }) {
  const { t } = useTranslation()
  const [filters, setFilters] = React.useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS }
  })
  const [globalFilterValue, setGlobalFilterValue] = React.useState('')
  const header = renderHeader(globalFilterValue, filters, setFilters, setGlobalFilterValue, t('common.table.search'))

  const navigate = useNavigate()
  const actionTemplate = (e) => {
    return (
      <div className="flex gap-2">
        <FolderIcon sx={{ color: '#14b8a6', cursor: 'pointer' }} onClick={() => navigate(`/hub/entity/${e.id}/details`)}/>
        <EditIcon sx={{ color: 'orange', cursor: 'pointer' }} onClick={() => navigate(`/hub/entity/${e.id}/edit`)}/>
        <DeleteEntity idEntity={e.id}>
          <DeleteIcon sx={{ color: '#f87171', cursor: 'pointer', fontSize: 25 }}/>
        </DeleteEntity>
      </div>
    )
  }

  const tableConfig = {
    value: entities,
    stateKey: 'dt-state-entities-hub-list',
    onFilter: (e) => setFilters(e.filters),
    paginator: true,
    paginatorTemplate: template2,
    dataKey: 'id',
    header,
    filters,
    globalFilterFields: ['created_at', 'name'],
    emptyMessage: t('admin.organisation.list.entity_list_notfound'),
    columns: [
      { field: 'name', header: t('common.table.name'), sortable: true, style: { width: '20%' } },
      { field: 'created_at', sortable: true, header: t('common.table.creation_date') },
      { body: actionTemplate, header: t('common.table.action'), style: { width: '20%' } }
    ]
  }

  const DataTableComponent = createDataTable(tableConfig)

  return (
    <div className="p-1">
        <div className="card mx-auto w-full border-1 border-gray-300">
          {DataTableComponent}
        </div>
    </div>

  )
}
EntityListTable.propTypes = {
  entities: PropTypes.array
}
export default EntityListTable

import React from 'react'
import { useTranslation } from 'react-i18next'
import { useGetEvaluationMethodNodesQuery, useGetEvaluationMethodsListQuery, useUpdateNodeMutation } from '../../../../features/evaluationMethod/evaluationMethodSlice'
import IndexAlert from '../../../../components/alerts/indexAlert'
import BeatLoader from 'react-spinners/BeatLoader'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'

function ModifyEvaluationMethodNode({ surveyNode, nodeData, setNodeData, setChosedNode, handleClose, scoringMethodList }) {
  const [updateNode, { isSuccess: isSuccessUpdate, isLoading: isLoadingUpdate, isError: isErrorUpdate, error }] = useUpdateNodeMutation()

  const handleUpdateEvaluationNode = async () => {
    await updateNode(nodeData)
  }
  const { data: evaluationMethods, isLoading: isLoadingEvaluationList, isSuccess: isSuccessEvaluationList } = useGetEvaluationMethodsListQuery()
  const { data: evaluationNodes, isLoading: isLoadingEvaluationNodes, isSuccess: isSuccessEvaluationNodes } = useGetEvaluationMethodNodesQuery(nodeData?.evaluation_method)
  const { t } = useTranslation()
  return (
    <div className="w-1/2">
      {isLoadingUpdate && <BeatLoader color="#086e7c" />}
      {isSuccessUpdate && <IndexAlert type="success" text="Modification de noeud avec succée" />}
      {isErrorUpdate && <IndexAlert type="danger" text={JSON.stringify(error?.data?.error)} />}

      <div className="md:w-full w-4/5 px-3 mb-6 md:mb-0 mt-1 relative rounded-sm ">
        
        {nodeData.type === 'static' &&
          <>
            <HelpOutlineIcon style={{ fill: '#188B15', fontSize: 20 }} />
            <label htmlFor="price" className="block text-lg font-medium text-gray-800"> {t('admin.survey.evaluation_model.create.fields.question_text')} </label>
            <p className="w-full pl-2 pr-5 text-lg  text-[#444444]">{nodeData?.text}</p>
            <label htmlFor="price" className="block text-lg font-medium text-gray-800"> {t('admin.survey.evaluation_model.create.fields.reference')}</label>
            <p className="w-full pl-2 pr-5 text-lg  text-[#444444]">{nodeData?.reference}</p>
          </>}
        {['category', 'Link'].includes(nodeData?.type) &&
          <>
            <label htmlFor="price" className="block text-lg font-medium text-gray-800"> {t('admin.survey.evaluation_model.create.fields.node_name')} </label>
            <input
              type="text"
              name="price" id="price" className="focus:ring-teal-500 w-1/2 focus:border-teal-500 block  w-full pl-2 pr-5 tsext-lg  text-[#444444] rounded-sm"
              style={{ border: '1px solid #e8e6e6' }} value={nodeData?.text}
              onChange={(e) => setNodeData({ ...nodeData, text: e.target.value, reference: e.target.value })}
            />
          </>
        }

      </div>
      {nodeData.type === 'link' &&
        <>
          {isLoadingEvaluationList && <div className='pt-3'><BeatLoader color="#086e7c" /></div>}
          {isSuccessEvaluationList &&
            <div className="md:w-1/2 w-4/5 px-3 mt-1 relative rounded-sm">
              <label className="block text-lg font-medium text-gray-800">
                {t('admin.survey.evaluation_model.create.fields.evaluation_method')}
              </label>
              <select
                className="focus:ring-teal-500 focus:border-teal-500 block max-w-fit pl-2 pr-2 text-lg text-[#444444] rounded-sm"
                style={{ border: '1px solid #e8e6e6' }}
                value={nodeData?.evaluation_method}
                onChange={(e) => setNodeData({ ...nodeData, evaluation_method: e.target.value, type: 'link' })}
              >
                <option></option>
                {evaluationMethods && evaluationMethods.map((node) => (
                  <option key={node.id} value={node.id}>{node.name}</option>
                ))}
              </select>
            </div>}
          {(nodeData?.evaluation_method && isLoadingEvaluationNodes) && <div className='pt-3'><BeatLoader color="#086e7c" /></div>}
          {isSuccessEvaluationNodes &&
            <div className="md:w-1/2 w-4/5 px-3 mt-1 relative rounded-sm">
              <label className="block text-lg font-medium text-gray-800">
                {t('admin.survey.evaluation_model.create.fields.chosen_node')}
              </label>
              <select
                className="focus:ring-teal-500 focus:border-teal-500 block max-w-fit pl-2 pr-2 text-lg text-[#444444] rounded-sm"
                style={{ border: '1px solid #e8e6e6' }}
                value={nodeData?.evaluation_node}
                onChange={(e) => setNodeData({ ...nodeData, evaluation_node: e.target.value, type: 'link' })}
              >
                <option></option>
                {evaluationNodes && evaluationNodes.map((node) => (
                  <option key={node.id} value={node.id}>{node.name}</option>
                ))}
              </select>
            </div>
          }
        </>
      }

      <div className="md:w-1/2 w-4/5 px-3 mt-1 relative rounded-sm">
        <label className="block text-lg font-medium text-gray-800">
          {t('admin.survey.evaluation_model.create.fields.node_weight')}
        </label>
        <input
          type="text" name="price" id="price"
          className="focus:ring-teal-500 focus:border-teal-500 block max-w-fit pl-2 pr-2 text-lg text-[#444444] rounded-sm"
          style={{ border: '1px solid #e8e6e6' }}
          onKeyPress={(event) => {
            if (!/[0-9.]/.test(event.key) || (event.key === '.' && event.target.value.includes('.'))) {
              event.preventDefault();
            }
          }}
          value={nodeData?.properties?.weight}
          onChange={(e) => setNodeData({ ...nodeData, properties: { weight: e.target.value } })}
        />
      </div>
      <div className="md:w-1/2 w-4/5 px-3 mt-1 relative rounded-sm ">
        <label className="block text-lg font-medium text-gray-800">
          {t('admin.survey.evaluation_model.create.fields.scoringMethod')}
        </label>
        <select
          className="focus:ring-teal-500 focus:border-teal-500 block max-w-fit pl-2 pr-2 text-lg text-[#444444] rounded-sm"
          style={{ border: '1px solid #e8e6e6' }}
          value={nodeData?.scoringMethod}
          onChange={(e) => setNodeData({ ...nodeData, scoringMethod: e.target.value })}
        >
          <option></option>
          {scoringMethodList && scoringMethodList.map((node) => (
            <option key={node} value={node}>{node}</option>
          ))}
        </select>
      </div>
      <div className='float-right flex gap-2'>
        <button
          onClick={() => handleUpdateEvaluationNode()}
          className="bg-teal-600 px-2 py-0.5 rounded-md mb-1 text-gray-100 text-md font-medium float-right p-1">
          <h5 className="text-white  w-16">{t('common.button.modify')}</h5>
        </button>
        <button
          onClick={() => handleClose()}
          className="bg-gray-200 px-2 py-0.5 rounded-md mb-1 text-gray-100 text-md font-medium float-right p-1">
          <h5 className="text-black  w-16">{t('common.button.close')}</h5>
        </button>
      </div>
    </div>
  )
}

export default ModifyEvaluationMethodNode

import React from 'react'
import DynamicNavBar from '../../../components/Bars/DynamicNavBar'
import { generalOptions, surveyOptions } from '../DynamicNavbarOptions'
import { useTranslation } from 'react-i18next'
import SurveyAccordion from './surveyAccordion'
import CreateSurveyNodeComponent from './charts/createSurveyNodeComponent'
import ModifySurveyNodeComponent from './charts/modifySurveyNodeComponent'
import DeleteSurveyNodeComponent from './charts/deleteSurveyNodeComponent'
import { useGetSurveyNodesQuery } from '../../../features/surveyNodesSlice/surveyNodesSlice'
import { useGetQuestionsListQuery } from '../../../features/questions/questionSlice'
import { useParams } from 'react-router-dom'

function createSurveyNew () {
  const { t } = useTranslation()
  const { id } = useParams()
  const { data: questions } = useGetQuestionsListQuery()
  const { data: surveyNodes, isSuccess } = useGetSurveyNodesQuery(id)

  const [chosenNode, setChosedNode] = React.useState({ node: '', action: '' })
  const [nodeSurvey, setNodeSurvey] = React.useState({ title: '', specification: '', question: [], categories: [], parent: '', node_type: 'category', survey: id })
  const handlemodify = (id) => {
    setChosedNode({ node: id, action: 'modif' })
    setNodeSurvey(surveyNodes.find((node) => node.id === id))
  }
  const handlecreate = (id) => {
    setChosedNode({ node: id, action: 'create' })
    const node = surveyNodes.find((node) => node.id === id)
    setNodeSurvey({ title: '', specification: '', question: [], categories: [], node_type: 'category', parent: id, survey: node?.survey })
  }

  return (
  <div className="w-full">
    <DynamicNavBar option="navbar.generalOptions.surveys" options={generalOptions}/>
    <DynamicNavBar option="navbar.surveyOptions.create_survey" options={surveyOptions}/>
    <div className="mt-2 w-full h-screan">
    <h5 className="text-center text-3xl font-medium text-[#444444] w-max-fit mb-3">{t('admin.survey.surveys.create.create_survey')}</h5>
    <div className='w-full flex'>
      <div className='w-1/4 border-r-2 border-gray-300 px-2 h-screen'>
      {isSuccess && <SurveyAccordion questionsList={questions || []} data={surveyNodes || null} handlemodify={handlemodify} handlecreate={handlecreate} setChosedNode={setChosedNode}/> }
      </div>
      <div className='w-3/4 px-3 h-screen'>
      {chosenNode.node &&
        <>
          {chosenNode.action === 'create' &&
            <div className="col-span-5 p-1 w-full h-full bg-[#fff] ">
              <CreateSurveyNodeComponent questions={questions} surveyNode={chosenNode} nodeSurvey={nodeSurvey} setNodeSurvey={setNodeSurvey} setChosedNode={setChosedNode}/>
             </div>}
          {chosenNode.action === 'modif' &&
            <div className="col-span-5 p-1 w-full h-full bg-[#fff] ">
              <ModifySurveyNodeComponent questions={questions} surveyNode={chosenNode} nodeSurvey={nodeSurvey} setNodeSurvey={setNodeSurvey} setChosedNode={setChosedNode}/>
            </div>}
          {chosenNode.action === 'delete' &&
            <div className="col-span-5 p-1 w-full h-full bg-[#fff] ">
              <DeleteSurveyNodeComponent surveyNode={chosenNode} nodeSurvey={nodeSurvey} setNodeSurvey={setNodeSurvey} setChosedNode={setChosedNode}/>
            </div>}
        </>
          }
      </div>
    </div>
    </div>
  </div>
  )
}

export default createSurveyNew

import React from 'react'
import { FilterMatchMode } from 'primereact/api'
import { useTranslation } from 'react-i18next'
import { createDataTable } from '../../../appComponents/datatable/data-table-factory'
import { renderHeader } from '../../../appComponents/datatable/utils/filter'

function ConsultantsTable ({ modify, onChangeEntity, entity, consultants }) {
  const { t } = useTranslation()


  const [filters, setFilters] = React.useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS }
  })
  const [globalFilterValue, setGlobalFilterValue] = React.useState('')
  const header = renderHeader(globalFilterValue, filters, setFilters, setGlobalFilterValue, t('common.table.search'))

  const tableActions = {
    setConsultants: (e) => {
      if (modify) {
        onChangeEntity({ ...entity, consultant: e.value.map(item => item.id) })
      }
    }
  }
  const tableConfig = {
    value: consultants || [],
    scrollable: true,
    scrollHeight: '250px',
    dataKey: 'id',
    filters,
    header,
    globalFilterFields: ['name', 'email'],
    emptyMessage: t('admin.survey.surveys.create.list_question_notFound'),
    selection: consultants?.filter((consultant) => entity?.consultant?.includes(consultant?.id)) || [],
    onSelectionChange: tableActions.setConsultants,
    columns: [
      { selectionMode: 'multiple', sortable: true, style: { maxWidth: '7%', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center' } },
      { header: t('common.table.name'), field: 'name', sortable: true, style: { maxWidth: '25%' } },
      { header: t('common.table.adresse_email'), field: 'email', sortable: true, style: { maxWidth: '70%' } }
    ]
  }

  const DataTableComponent = createDataTable(tableConfig)

  return (
    <div className="card w-full" name="consultants_table">
      {DataTableComponent}
    </div>

  )
}
export default ConsultantsTable

import React from 'react'
import DocumentList from '../../../components/list/documentList'
import DynamicNavBar from '../../../components/Bars/DynamicNavBar'
import { generalOptions } from '../DynamicNavbarOptions'
import { useGetDocumentsByTypeQuery } from '../../../features/documents/documentSlice'
import { useTranslation } from 'react-i18next'



function DocumentTracking ({}) {
  const { t } = useTranslation()
  const { data: sueveys } = useGetDocumentsByTypeQuery('export_survey')

  return (
        <div className="w-full pb-16">
            <DynamicNavBar option="navbar.generalOptions.documents" options={generalOptions}/>
            <div className="w-full flex flex-col items-center gap-8  static mt-4" >
                <div className=" md:w-2/3 lg:w-4/6 sm:w-2/3 bg-wite md:mx-8 pb-8">
                    <div className="mt-1 shadow-sm rounded-md w-full mx-2 bg-white px-4 py-4 flex flex-col gap-4">
                        <div className="flex flex-col mb-5 justify-between w-full">
                            <h5 className="text-2xl font-medium  text-[#444444] w-max-fit">{t('consultant.document.title.survey_files')} </h5>
                            <DocumentList data={sueveys || []} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
  )
}

export default DocumentTracking

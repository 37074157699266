export const dataMatrix = {
  '0f16b0c4-ee67-4ca0-9b81-7e005bb822af': {
    label: 'Accès a des informations de qualité',
    internal_risk: 8.0,
    internal_financial_impact: 10.0,
    internal_environmental_impact: 13,
    external_risk: 14.0,
    external_financial_impact: 9.0,
    external_environmental_impact: 9.0,
    probability: 1,
    alignement: 3.3,
    parent_text: 'Client',
    parent_uuid: 'ea2f5a4e-27cf-4b33-b989-8e4d12ffca5c'
  },
  '241e18a8-2e0d-4e28-a9d9-7cfa2a43da8d': {
    label: 'Travail forcé',
    internal_risk: 4.0,
    internal_financial_impact: 2.0,
    internal_environmental_impact: 3,
    external_risk: 8.0,
    external_financial_impact: 16.0,
    external_environmental_impact: 16.0,
    probability: 0.3,
    alignement: 13,
    parent_text: 'Relations et conditions de travail',
    parent_uuid: 'c3db06c2-bda1-497a-b5ce-0214f8e513d3'
  },
  '71127643-d2eb-422f-bdd1-f1b52fc6a083': {
    label: "Impacts sur les droits de l'homme",
    internal_risk: 4.0,
    internal_financial_impact: 4.0,
    internal_environmental_impact: 3,
    external_risk: 0,
    external_financial_impact: 18.0,
    external_environmental_impact: 18.0,
    probability: 0.2,
    alignement: 4,
    parent_text: 'Relations et conditions de travail',
    parent_uuid: 'c3db06c2-bda1-497a-b5ce-0214f8e513d3'
  },
  '71d20de8-f8bf-4c8a-9a59-186e41493e2d': {
    label: "l'égalité H/F",
    internal_risk: 8.0,
    internal_financial_impact: 9.0,
    internal_environmental_impact: 3,
    external_risk: 17.2,
    external_financial_impact: 16.5,
    external_environmental_impact: 16.5,
    probability: 0.5,
    alignement: 5,
    parent_text: 'Relations et conditions de travail',
    parent_uuid: 'c3db06c2-bda1-497a-b5ce-0214f8e513d3'
  },
  '76efcdaa-41fd-40be-9560-c0ce1cf6b158': {
    label: 'Emploi sur',
    internal_risk: 12.0,
    internal_financial_impact: 10.0,
    internal_environmental_impact: 13,
    external_risk: 20.0,
    external_financial_impact: 7.0,
    external_environmental_impact: 7.0,
    probability: 0.5,
    alignement: 7,
    parent_text: 'Relations et conditions de travail',
    parent_uuid: 'c3db06c2-bda1-497a-b5ce-0214f8e513d3'
  },
  'a26e1b19-8430-4ff0-b144-b4ede3929c49': {
    label: 'Consentement libre',
    internal_risk: 4.0,
    internal_financial_impact: 12.0,
    internal_environmental_impact: 13,
    external_risk: 0,
    external_financial_impact: 6.0,
    external_environmental_impact: 6.0,
    probability: 0.5,
    alignement: 12,
    parent_text: 'Relations et conditions de travail',
    parent_uuid: 'c3db06c2-bda1-497a-b5ce-0214f8e513d3'
  },
  'd4f37ebb-f591-4fbf-9df2-a0a9a6058732': {
    label: 'Temps de travail',
    internal_risk: 16.0,
    internal_financial_impact: 12.0,
    internal_environmental_impact: 13,
    external_risk: 20.0,
    external_financial_impact: 4.0,
    external_environmental_impact: 4.0,
    probability: 0.8,
    alignement: 5,
    parent_text: 'Relations et conditions de travail',
    parent_uuid: 'c3db06c2-bda1-497a-b5ce-0214f8e513d3'
  },
  'd7f5de30-0282-4192-92e2-cc3dfa9bca5e': {
    label: 'Autodétermination',
    internal_risk: 4.0,
    internal_financial_impact: 19.0,
    internal_environmental_impact: 13,
    external_risk: 0,
    external_financial_impact: 5.0,
    external_environmental_impact: 5.0,
    probability: 0.7,
    alignement: 8,
    parent_text: 'Relations et conditions de travail',
    parent_uuid: 'c3db06c2-bda1-497a-b5ce-0214f8e513d3'
  },
  'e7ae7192-e12f-4eff-8842-4930df41614c': {
    label: 'impacts liés à la sécurité',
    internal_risk: 4.0,
    internal_financial_impact: 2.0,
    internal_environmental_impact: 3,
    external_risk: 16.8,
    external_financial_impact: 6.0,
    external_environmental_impact: 6.0,
    probability: 0.9,
    alignement: 5,
    parent_text: 'Relations et conditions de travail',
    parent_uuid: 'c3db06c2-bda1-497a-b5ce-0214f8e513d3'
  },
  'f2c7adf4-9d2c-4a10-9dfa-a69e9db24d8d': {
    label: 'Droits culturels',
    internal_risk: 4.0,
    internal_financial_impact: 7.0,
    internal_environmental_impact: 3,
    external_risk: 8.0,
    external_financial_impact: 10.0,
    external_environmental_impact: 10.0,
    probability: 0.6,
    alignement: 6,
    parent_text: 'Relations et conditions de travail',
    parent_uuid: 'c3db06c2-bda1-497a-b5ce-0214f8e513d3'
  }
}

import React from 'react'
import IndexAlert from '../../../../components/alerts/indexAlert'
import { useGetConsultantsQuery } from '../../../customers/customersSlice'
import ConsultantsTable from '../consultantsTable'
import { useTranslation } from 'react-i18next'
import BeatLoader from 'react-spinners/BeatLoader'

function ModifyConsultantsList ({ changeEntity, entity }) {
  const { t } = useTranslation()
  const { data: consultants, isLoading, isSuccess, isError, Error } = useGetConsultantsQuery()

  return (
    <>
      <section className="w-full  pb-5">
        <div className="flex flex-col gap-2 w-full">
          <div className="md:w-2/3 sm:w-1/2 pl-2 mt-2">
            <IndexAlert type="danger" text={t('admin.organisation.edit.contrainte_modif_consultant')} />
          </div>
        </div>
            <div className="flex flex-col mt-1 px-2 pt-1">
              {isLoading && <BeatLoader color="#086e7c"/>}
              {isSuccess && <ConsultantsTable onChangeEntity={changeEntity} entity={entity} consultants={consultants} modify={true}/> }
            </div>
      </section>

    </>
  )
}

export default ModifyConsultantsList

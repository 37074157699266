import React from 'react'
import { useTranslation } from 'react-i18next'
import { selectAllActivities } from '../../../features/activitiesSector/activitiesSlice'
import { useSelector } from 'react-redux'

function EntityFields ({ entity, onChangeEntity }) {
  const { t } = useTranslation()
  const activivitySectors = useSelector(selectAllActivities)
  const handleChangeEntity = (key, value) => {
    onChangeEntity({ ...entity, entityInfo: { ...entity.entityInfo, [key]: value } })
  }
  const handleChange = event => {
    const min = 0
    const max = 999999
    const value = Math.max(min, Math.min(max, Number(event.target.value)))
    onChangeEntity({ ...entity, employee_Number: value })
  }

  return (
    <section className='min-h-fit'>
      <div className="md:w-1/2 w-4/5 px-3 md:mb-0 mt-1 relative rounded-sm">
        <label htmlFor="entity_name" className="block text-lg font-medium text-gray-800">
          {t('admin.organisation.create.fields.entity_name')}
        </label>
        <input type="text" name="entity_name" value={entity?.entityInfo?.entityName || ''} onChange={(e) => handleChangeEntity('entityName', e.target.value)} id="price" className="focus:ring-teal-500
          focus:border-teal-500 block  w-full pl-2 pr-5 tsext-lg  text-[#444444] rounded-sm" style={{ border: '1px solid #e8e6e6' }}/>
      </div>
      <div className="md:w-1/2 w-4/5 px-3 md:mb-0 mt-1 relative rounded-sm">
        <label htmlFor="nafCode" className="block text-lg font-medium text-gray-800">
          {t('admin.organisation.create.fields.nafCode')}
        </label>
        <input type="text" name="naf_code" value={entity?.entityInfo?.nafCode || ''} onChange={(e) => handleChangeEntity('nafCode', e.target.value)} id="price" className="focus:ring-teal-500
          focus:border-teal-500 block  w-full pl-2 pr-5 tsext-lg  text-[#444444] rounded-sm" style={{ border: '1px solid #e8e6e6' }}/>
      </div>
      <div className="md:w-1/2 w-4/5 px-3 md:mb-0 mt-1 relative rounded-sm">
        <label htmlFor="entity_address" className="block text-lg font-medium text-gray-800">
          {t('admin.organisation.create.fields.entityAdress')}
        </label>
        <input type="text" name="entity_address" value={entity?.entityInfo?.adress || ''} onChange={(e) => handleChangeEntity('adress', e.target.value)} id="price" className="focus:ring-teal-500
          focus:border-teal-500 block  w-full pl-2 pr-5 text-lg  text-[#444444] rounded-sm" style={{ border: '1px solid #e8e6e6' }}/>
      </div>
      <div className="md:w-1/2 w-4/5 px-3 md:mb-0 mt-1 relative rounded-sm">
        <label htmlFor="entity_nic" className="block text-lg font-medium text-gray-800">
          {t('admin.organisation.create.fields.nic')}
        </label>
        <input type="text" name="entity_nic" value={entity?.entityInfo?.nic || ''} onChange={(e) => handleChangeEntity('nic', e.target.value)} id="price" className="focus:ring-teal-500
          focus:border-teal-500 block  w-full pl-2 pr-5 tsext-lg  text-[#444444] rounded-sm" style={{ border: '1px solid #e8e6e6' }}/>
      </div>
      <div className="md:w-1/2 w-4/5 px-3 md:mb-0 mt-1 relative rounded-sm">
        <label htmlFor="entity_siren" className="block text-lg font-medium text-gray-800">
          {t('admin.organisation.create.fields.siren_Number')}
        </label>
        <input type="text" name="entity_siren" value={entity?.entityInfo?.siren || ''} onChange={(e) => handleChangeEntity('siren', e.target.value)} id="price" className="focus:ring-teal-500
          focus:border-teal-500 block  w-full pl-2 pr-5 tsext-lg  text-[#444444] rounded-sm" style={{ border: '1px solid #e8e6e6' }}/>
      </div>
      <div className="md:w-1/2 w-4/5 px-3 mt-1 relative rounded-sm ">
        <label
          htmlFor="entity_activity_sector"
          className="block text-lg font-medium text-gray-800">
          {t('admin.organisation.create.fields.activity_sector')}
        </label>
        <select name="entity_activity_sector" className="focus:ring-teal-500 focus:border-teal-500 block border-1  border-[#e8e6e6] w-full pl-1 pr-2 text-cxl py-1  text-[#444444]
          rounded-sm" value={entity?.entityInfo?.activitysector || ''}
          onChange={(e) => {
            handleChangeEntity('activitysector', e.target.value)
          } }>
          <option></option>
          {activivitySectors.map((sector) => <option key={sector.id} value={sector.id}>{sector.reference}-{sector.name}</option>)}
        </select>
      </div>
      <div className="md:w-1/2 w-4/5 px-3 mt-1 relative rounded-sm ">
        <label className="block text-lg font-medium text-gray-800">
          {t('admin.organisation.create.fields.nbr_employee')}
        </label>
        <input type="text" name="entity_nb_employee" id="price" className="focus:ring-teal-500 focus:border-teal-500 block  w-full pl-2 pr-2 tsext-lg  text-[#444444]
            rounded-sm" style={{ border: '1px solid #e8e6e6' }}
          onKeyPress={(event) => {
            if (!/[0-9]/.test(event.key)) {
              event.preventDefault()
            }
          }}
          value={entity?.employee_Number ? entity.employee_Number : ''}
          onChange={(e) => handleChange(e)} />
      </div>
      <div className="md:w-2/3 w-3/4 px-3 mt-1 relative rounded-sm min-h-fit">
        <label className="block text-lg font-medium text-gray-800">
          {t('admin.organisation.create.fields.description')}
        </label>
        <textarea
          name="entity_description"
          id="description"
          rows="6"
          className="focus:ring-teal-500 focus:border-teal-500 block w-full pl-2 pr-2 text-lg text-[#444444] rounded-sm"
          style={{ border: '1px solid #e8e6e6' }}
          value={entity?.description ? entity.description : ''}
          onChange={(e) => onChangeEntity({ ...entity, description: e.target.value })}
        />
      </div>

    </section>
  )
}

export default EntityFields

import React, { useState, useRef } from 'react'
import DataTableImportUserList from './importTables/DataTableImportUserList'
import { read, utils, writeFile } from 'xlsx'
import CreateUser from './createUser'
import { mockData } from './setMemberMockData'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import ReplayIcon from '@mui/icons-material/Replay'
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types' 
import ClientData from './clientData'

function SetMembersList({ onChangeEntity, entity }) {
  const { t } = useTranslation()
  const [file, setFile] = React.useState()
  const [selectedData, setSelectedData] = useState(null)
  let [users, setUsers] = useState(entity?.entity_members ? entity?.entity_members : [])
  const fileRef = useRef()
  React.useEffect(() => {
    if (users) {
      onChangeEntity({ ...entity, entity_members: users })
    }
  }, [users])
  const DownloadTemplate = () => {
    const wb = utils.book_new(); const ws = utils.json_to_sheet(mockData)
    utils.book_append_sheet(wb, ws, 'Liste_employee')
    writeFile(wb, 'entity_members_template.xlsx')
  }
  const handleImport = (e) => {
    const files = e.target.files
    if (files.length) {
      const file = files[0]
      setFile(file)
      const reader = new FileReader()
      reader.onload = (event) => {
        const wb = read(event.target.result)
        const sheets = wb.SheetNames

        if (sheets.length) {
          const importRows = utils.sheet_to_json(wb.Sheets[sheets[0]])
          const usersRow = [...users, ...importRows]
          let rows = [...new Set(usersRow)]
          rows = rows.map((row) => row = { ...row, id: rows.indexOf(row) })
          setUsers(rows)
        }
      }
      reader.readAsArrayBuffer(file)
    }
  }

  const clearTable = () => {
    fileRef.current.value = ''
    setUsers(users = [])
  }
  const deleteSelectedUsers = () => {
    if (selectedData) {
      setUsers(users.filter((user) => !selectedData.includes(user)))
    }
  }

  return (
    <section>
      <div className="flex justify-between gap-2 mt-1 w-full">
        <div>
          <h5 className="block text-xl font-medium text-gray-800 ">{t('admin.organisation.create.fields.importListEmpl')}</h5>
          <div className="flex max-h-fit pt-1">
            <label htmlFor="inputGroupFile" className="bg-teal-600 px-2 py-0.5 rounded-md mb-1 text-gray-100 text-md font-medium float-right p-1">{t('common.buttons.choose_file')}</label>
            <div>
              <span className="text-gray-700 ml-1">{file?.name || t('common.no_file_selected')}</span>
            </div>
            <input type="file" name="file" ref={fileRef} className="opacity-0 absolute"
              id="inputGroupFile" required onChange={(e) => handleImport(e)}
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            />
          </div>
        </div>
        <div >
          <h5 className="block text-lg font-medium text-gray-800" style={{ cursor: 'pointer' }} onClick={DownloadTemplate}>{t('admin.organisation.create.fields.downloadForm')} <DownloadForOfflineIcon sx={{ color: '#444' }} /></h5>
        </div>
      </div>
      <div className="flex flex-col  flex-wrap gap-2 mt-2">
        <h5 className="block text-lg font-medium text-gray-800 ">{t('admin.organisation.create.fields.organisation_member_list')}</h5>
        <div className="md:w-full w-3/5   px-3 mb-6 md:mb-0 mt-1 relative rounded-sm  gap-8">
          <div className="flex gap-2 min-w-fit">
            <CreateUser users={users} onChangeUsers={setUsers}>
              <button className="border-2 border-gray-300 rounded-md min-w-fit flex p-0.5 bg-white mb-1" style={{ cursor: 'pointer' }}>
                <AddIcon sx={{ color: 'green' }} />
                <h5 className="text-md font-medium px-1">{t('admin.organisation.create.common.add_member')}</h5>
              </button>
            </CreateUser>
            <button className="border-2 border-gray-300 rounded-md min-w-fit flex p-0.5 bg-white mb-1" style={{ cursor: 'pointer' }}
              onClick={() => deleteSelectedUsers()}>
              <DeleteIcon sx={{ color: '#ef4444', cursor: 'pointer' }} />
              <h5 className="text-md font-medium px-1">{t('admin.organisation.create.common.delete')} {selectedData > 0 ? selectedData.length + 'items' : undefined}</h5>
            </button>
            {users.length !== 0 &&
              <button className="border-2 border-gray-300 rounded-md min-w-fit flex p-0.5 bg-white mb-1" style={{ cursor: 'pointer' }} onClick={() => clearTable()}>
                <ReplayIcon sx={{ color: '#4682B4' }} />
                <h5 className="text-md font-medium px-1">{t('admin.organisation.create.common.initilize_list')}</h5>
              </button>}
          </div>
          {<DataTableImportUserList setSelectedData={setSelectedData} userList={users || []} changeUserList={setUsers} />}
        </div>
      </div>
    </section>
  )
}

SetMembersList.propTypes = {
  onChangeEntity: PropTypes.func.isRequired,
  entity: PropTypes.object,
  onChangeStep: PropTypes.func.isRequired
}

export default SetMembersList

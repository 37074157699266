import React from 'react'
import { Outlet } from 'react-router-dom'
import useSessionManager from '../../features/hooks/useSessionManager'

const RequireSession = () => {
  useSessionManager()

  return <Outlet />
}

export default RequireSession

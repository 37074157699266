import React from 'react'
import { Outlet, Routes, Route } from 'react-router-dom'
import CreateEntity from './createEntity'
import EditEntity from './editEntity'

import EntitiesTracking from './entitiesTracking'
import DetailsEntity from './detailsEntity'

function IndexEntity () {
  return (
    <>
        <Outlet/>
        <Routes>
            <Route path="create" exact element={<CreateEntity/>}></Route>
            <Route path="edit/:id" exact element={<EditEntity/>}></Route>
            <Route path="details/:id" exact element={<DetailsEntity/>}></Route>
            <Route path="" exact element={<EntitiesTracking/>}></Route>
        </Routes>
    </>
  )
}

export default IndexEntity

import React from 'react'
import { getFormat } from '../components/prepareOutoutResult'
import { SunburstChart } from './SunburstChart.tsx'
import { Spinner } from '../../../../components/loadData/spinner'
import { useTranslation } from 'react-i18next'
import Score from '../utils/score'

function EvaluationResultCharts({ evaluation, isLoading, isSuccess }) {
  const { t } = useTranslation()
  const [evaluationData, setEvaluationData] = React.useState()
  const [selectedCategory, setSelectedCategory] = React.useState()
  const [evaluationTree, setEvaluationTree] = React.useState()

  React.useEffect(() => {
    if (evaluation) {
      setEvaluationData(evaluation)
    }
  }, [evaluation])

  React.useEffect(() => {
    if (evaluationData) {
      setEvaluationTree(getFormat(evaluationData))
    }
  }, [evaluationData])

  if (isLoading) {
    return (
      <div className="mt-8 ">
        <Spinner />
      </div>
    )
  }
  if (isSuccess && evaluationTree) {
    return (
      <div className="flex justify-center w-full mt-16 gap-2 h-500">
        <div className='flex bg-white gap-8 rounded-md p-1 h-full w-1/4 pr-8 px-2' >
          <div className="flex flex-col">
            <h5 className="block text-xl font-medium text-gray-800">{t('consultant.evaluation.result.evaluationDetail')}</h5>
            <div className="pl-3">
              <h5 className="block text-md font-medium text-gray-800">{t('consultant.evaluation.result.score')}</h5>
              <Score score={evaluation?.properties?.score} />
            </div>
          </div>
          {selectedCategory
            ? <div className="flex flex-col justify-start float-left">
              <h5 className="block text-xl font-medium text-gray-800">{t('consultant.evaluation.result.categoryDetail')}</h5>
              <h5 className="block text-md font-medium text-gray-800">{selectedCategory?.name || ''}</h5>
              <h5 className="block bg-gray-300 max-w-fit px-1 rounded-md text-md font-medium text-gray-800 mb-1">{t('consultant.evaluation.result.score')}</h5>
              <Score score={selectedCategory?.properties?.score} />
            </div>
            : undefined
          }
        </div>
        <div className="mx-auto float-right flex justify-center" >
          <SunburstChart data={evaluationTree} onChangeCategory={(val) => setSelectedCategory(val)} />
        </div>
      </div>
    )
  }
}
export default EvaluationResultCharts

import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useGetMaterialityMatrixQuery } from '../../../features/materialityMatrix/materialityMatrixSlice'
import { useTranslation } from 'react-i18next'
import { BubblePlot } from '../../../components/charts/bubbleChart/BubblePlot.tsx'
import { axis, pointsColor, pointsOrder } from './filterParam'
import { dataMatrix } from '../../../testData/data.js'
import SelectParams from './components/selectParams'
import SelectParam from './components/selectParam'

function MaterialityMatrixEvaluation () {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { id_matrix } = useParams()
  const { idEntity } = useParams()
  const [matrixResults, setData] = React.useState()
  const [matrixRedultsFiltered, setMatrixRedultsFiltered] = React.useState()
  const [parentTexts, setParentTexts] = React.useState()
  const [showedNumber, setShowedNumber] = React.useState()
  const [category, setCategory] = React.useState('all')
  const [bubbleCount, setBubbleCount] = React.useState(0)
  const [XAxisParam, setXAxisParam] = React.useState()
  const [YAxisParam, setYAxisParam] = React.useState()
  const [PointsSizeParam, setPointsSizeParam] = React.useState()
  const [PointsOrderParam, setPointsOrderParam] = React.useState()
  const [PointsColorParam, setPointsColorParam] = React.useState(pointsColor?.find((data) => data.filter_name === 'default') || undefined)

  React.useEffect(() => {
    if (matrixResults) {
      const parentTexts = [...new Set(matrixResults.map(item => item.parent_text))]
      setParentTexts(parentTexts)
    }
  },
  [matrixResults])

  const filteredCategory = () => {
    const data = category === 'all' ? matrixResults : matrixResults?.filter(bubble => bubble.parent_text === category)
    setMatrixRedultsFiltered(data)
    setBubbleCount(data?.length)
  }
  const filterNumber = () => {
    if (category === 'all') {
      const data = matrixResults
        .sort((a, b) => b.rank - a.rank)
        .slice(0, showedNumber)
      setMatrixRedultsFiltered(data)
    } else {
      const data = matrixResults?.filter(item => item.parent_text === category)
        .sort((a, b) => b.rank - a.rank)
        .slice(0, showedNumber)
      setMatrixRedultsFiltered(data)
    }
  }

  React.useEffect(() => {
    if (category) {
      filteredCategory()
    }
    if (showedNumber) {
      filterNumber()
    }
  }, [category, showedNumber])

  const { data: materialityMatrixList } = useGetMaterialityMatrixQuery(id_matrix)

  React.useEffect(() => {
    if (dataMatrix) {
      setData(Object.values(dataMatrix))
      setMatrixRedultsFiltered(Object.values(dataMatrix))
      setBubbleCount(Object.values(dataMatrix)?.length)
    }
  }, [materialityMatrixList])

  const XAxisFct = (selectedValue) => {
    if (selectedValue) {
      setXAxisParam((prev) => ({
        ...prev,
        [selectedValue.type]: selectedValue // Update state dynamically
      }))
    }
  }
  const YAxisFct = (selectedValue) => {
    if (selectedValue) {
      setYAxisParam((prev) => ({
        ...prev,
        [selectedValue.type]: selectedValue // Update state dynamically
      }))
    }
  }
  const PointsSizeFct = (selectedValue) => {
    if (selectedValue) {
      setPointsSizeParam((prev) => ({
        ...prev,
        [selectedValue.type]: selectedValue // Update state dynamically
      }))
    }
  }
  console.log('PointsSizeParam', PointsSizeParam)
  const pointsColorFct = (selectedValue) => {
    setPointsColorParam(pointsColor.find((data) => data.filter_name === selectedValue) || undefined)
  }
  const pointsOrderFct = (selectedValue) => {
    setPointsOrderParam(pointsColor.find((data) => data.filter_name === selectedValue) || undefined)
  }
  console.log('data', matrixRedultsFiltered)

  return (
    <div className="w-full flex justify-center px-auto">
      <div className="md:w-2/3 lg:w-2/3 sm:w-2/3 bg-wite md:mx-8 pb-8 mt-4">
        <div className="mt-1 shadow-sm rounded-md w-full mx-2 bg-white px-4 py-4 flex flex-col gap-4" >
          <div className="flex my-2 justify-between w-full">
            <h5 className="text-2xl font-medium  text-[#444444] w-max-fit">{t('consultant.materiality_matrix.materiality_matrix')} </h5>
            <div className="bg-teal-600 w-max-fit px-2 py-0.5 rounded-md mb-1 text-gray-100 text-md font-medium"
              style={{ cursor: 'pointer' }} onClick={() => navigate(`/hub/entity/${idEntity}/materiality_matrix/create`)}>{t('consultant.buttons.createMaterialityMatrix')}
            </div>
          </div>
          <div className='flex'>
            <BubblePlot data={matrixRedultsFiltered || []} width={800} height={800} params={{ x_param: XAxisParam, y_param: YAxisParam, colors: PointsColorParam, size: PointsSizeParam, order: pointsOrder }} />
            <div className='w-full flex-col'>
              <SelectParams selectFct={XAxisFct} data={axis || []} title={t('consultant.materiality_matrix.labels.axe_abscisses')}/>
              <SelectParams selectFct={YAxisFct} data={axis || []} title={t('consultant.materiality_matrix.labels.axe_ordonnees')}/>
              <SelectParams selectFct={PointsSizeFct} data={axis || []} title={t('consultant.materiality_matrix.labels.point_size')}/>
              <SelectParam selectFct={pointsColorFct} data={pointsColor || []} title={t('consultant.materiality_matrix.labels.point_color')}/>
              {// <SelectParam selectFct={pointsOrderFct} data={pointsOrder || []} title="Ordre des points"/>
              }
            </div>
          </div>
          <div className='flex'>
          <select className="w-1/4 p-2 border border-gray-300 rounded-md" onChange={(e) => setShowedNumber(e.target.value)}>
          {Array.from({ length: bubbleCount }, (_, i) => i + 1).map((value) =>
            <option key={value} value={value}>{value}</option>)}
        </select>
            <select className="w-1/4 p-2 border border-gray-300 rounded-md" onChange={(e) => setCategory(e.target.value)}>
              <option value="all">{t('common.show_all')}</option>
              {parentTexts && parentTexts.map((value) =>
                <option key={value} value={value}>{value}</option>)}
            </select>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MaterialityMatrixEvaluation

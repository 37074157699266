import React from 'react'

const Score = ({ score }) => {
  const { numerator, denominator } = score
  // TODO : find a more elegant way =
  const scoreNumber = Math.round((numerator / denominator) * 20 * 10) / 10

  if (scoreNumber >= 15) {
    return (
      <span className="bg-green-300 min-w-fit text-gray-50 w-16 text-center rounded-md p-1">{scoreNumber + ' / 20'}</span>)
  }
  if (scoreNumber < 15 && scoreNumber > 10) {
    return (
      <span className="bg-yellow-300 min-w-fit text-gray-50 w-16 text-center rounded-md p-1">{scoreNumber + ' / 20'}</span>)
  }
  if (scoreNumber < 15) {
    return (
      <span className= "bg-red-300 min-w-fit text-gray-50 w-16 text-center rounded-md p-1">{scoreNumber + ' / 20'}</span>)
  }
}
export default Score

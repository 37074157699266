import React from 'react'
import CompaignsList from '../../../features/campaign/CompaignsList'
import { useNavigate, useParams } from 'react-router-dom'
import { useGetCampaignsQuery } from '../../../features/campaign/campaignsSlice'
import { useTranslation } from 'react-i18next'
function Campaigns () {
  const navigate = useNavigate()
  const { idEntity } = useParams()
  const { data: campaigns } = useGetCampaignsQuery(idEntity)
  const { t } = useTranslation()

  return (
    <div className="w-full flex justify-center px-auto ">
      <div className="md:w-2/3 lg:w-2/3 sm:w-2/3 bg-wite md:mx-8 pb-8 mt-4">
        <div className="mt-1 my-5 shadow-sm rounded-md w-full mx-2 bg-white px-4 py-4 flex flex-col gap-4" >
          <div className="flex justify-between w-full">
            <h5 className="text-2xl font-medium  text-[#444444] w-max-fit">{t('consultant.campaign.Liste_campaigns')} </h5>
            <div className="bg-teal-600 px-2 py-0.5 rounded-md mb-1 text-gray-100 text-md font-medium"
              style={{ cursor: 'pointer' }} onClick={() => navigate(`/hub/entity/${idEntity}/campaigns/create`)}>{t('consultant.buttons.create_campaign')}</div>
          </div>
          {campaigns && <CompaignsList compaigns={campaigns} />}
        </div>
      </div>
    </div>
  )
}

export default Campaigns

import React from 'react'
import { FilterMatchMode } from 'primereact/api'
import { useTranslation } from 'react-i18next'
import { createDataTable } from '../../appComponents/datatable/data-table-factory'
import { renderHeader } from '../../appComponents/datatable/utils/filter'
import { template2 } from '../../appComponents/datatable/utils/paginators'
import { axiosDownloadDocument } from '../../features/documents/axiosDownloadFile'
import DownloadIcon from '@mui/icons-material/Download'

function DocumentList ({ data }) {
  const { t } = useTranslation()

  const actionTemplate = (e) => {
    return (
      <div className="flex gap-2">
        {/* <RemoveRedEyeIcon sx={{ color: '#1b674c', cursor: 'pointer' }} onClick={() => navigate(`/hub/entity/${idEntity}/evaluation/${e.id}`)}/> */}
        <DownloadIcon sx={{ color: '#1b674c', cursor: 'pointer' }} onClick={() => axiosDownloadDocument(e.id, e.file_name)} />
      </div>
    )
  }
  const [filters, setFilters] = React.useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS }
  })
  const [globalFilterValue, setGlobalFilterValue] = React.useState('')
  const header = renderHeader(globalFilterValue, filters, setFilters, setGlobalFilterValue, t('common.table.search'))

  const tableConfig = {
    value: data,
    stateKey: 'dt-state-docuemnts-hub-list',
    onFilter: (e) => setFilters(e.filters),
    paginator: true,
    paginatorTemplate: template2,
    dataKey: 'id',
    filters,
    header,
    globalFilterFields: ['file_name', 'entity', 'uploaded_at', 'doc_type'],
    emptyMessage: t('consultant.evaluation.table.evaluation_methods_listNotFound'),
    columns: [
      { field: 'file_name', sortable: true, header: t('common.table.file_name') },
      { field: 'entity', sortable: true, header: t('common.table.entity') },
      { field: 'uploaded_at', sortable: true, header: t('common.table.creation_date') },
      { field: 'doc_type', sortable: true, header: t('common.table.doc_type') },
      { body: actionTemplate, header: t('common.table.action') }]
  }

  const DataTableComponent = createDataTable(tableConfig)
  return (
    <div>
        <div className="card mx-auto w-full border-1 border-gray-300 ">
          {DataTableComponent}
        </div>
    </div>

  )
}
export default DocumentList


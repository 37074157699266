import { createSelector, createEntityAdapter } from '@reduxjs/toolkit'
import { apiSlice } from '../api/apiSlice'

const usersAdapter = createEntityAdapter({
})
const initialState = usersAdapter.getInitialState({
  data: '',
  status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
  count: 0
})

export const usersApiSlice = apiSlice.injectEndpoints({
  reducerPath: 'Users',
  endpoints: builder => ({
    getUsers: builder.query({
      query: () => '/user/admins',
      transformResponse: responseData => {
        const finalUsers = responseData
        return usersAdapter.setAll(initialState, finalUsers)
      },
      providesTags: ['Users']
    }),
    getUser: builder.query({
      query: (id) => `user/${id}`,
      transformResponse: responseData => {
        return responseData
      },
      providesTags: ['Users']
    }),
    getConsultants: builder.query({
      query: (id) => `user/${id}`,
      transformResponse: responseData => {
        return responseData
      },
      providesTags: ['Users']
    }),
    createAdmin: builder.mutation({
      query: (entity_data) => ({
        url: '/user/admin/create',
        method: 'POST',
        body: entity_data
      }),
      invalidatesTags: ['Users']
    }),
    blockAdmin: builder.mutation({
      query: (idAdmin) => ({
        url: `/user/admin/block/${idAdmin}`,
        method: 'PATCH'
      }),
      invalidatesTags: ['Users']
    }),
    unblockAdmin: builder.mutation({
      query: (idAdmin) => ({
        url: `/user/admin/unblock/${idAdmin}`,
        method: 'PATCH'
      }),
      invalidatesTags: ['Users']
    }),
    deleteAdmin: builder.mutation({
      query: (idAdmin) => ({
        url: `/user/admin/delete/${idAdmin}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Users']
    }),
    modifyAdmin: builder.mutation({
      query: (user) => ({
        url: `/user/admin/modify/${user.id}`,
        method: 'PATCH',
        body: user
      }),
      invalidatesTags: ['Users']
    })

  })
})

export const {
  useGetUsersQuery,
  useGetUserQuery,
  useGetConsultantsQuery,
  useCreateAdminMutation,
  useModifyAdminMutation,
  useDeleteAdminMutation,
  useBlockAdminMutation,
  useUnblockAdminMutation
} = usersApiSlice

export const selectUsersResult = usersApiSlice.endpoints.getUsers.select()

export const selectUsersData = createSelector(
  selectUsersResult,
  usersResult => usersResult.data
)

export const {
  selectAll: selectAllUsers,
  selectById: selectSingleUser
} = usersAdapter.getSelectors(state => selectUsersData(state) ?? initialState)

import React from 'react'
import { useTranslation } from 'react-i18next'
import { useCreateNodeMutation } from '../../../../features/evaluationMethod/evaluationMethodSlice'
import IndexAlert from '../../../../components/alerts/indexAlert'
import BeatLoader from 'react-spinners/BeatLoader'
import QuestionList from '../charts/questionList'

function CreateEvaluationMethodNode ({ questions, surveyNode, nodeData, setNodeData, setChosedNode, handleClose, scoringMethodList }) {
  const [createNode, { isSuccess: isSuccessCreate, isLoading: isLoadingCreate, isError: isErrorCreate, error }] = useCreateNodeMutation()
  const { t } = useTranslation()

  const handleAddQuestions = (val) => {
    setNodeData({ ...nodeData, questions: val })
  }
  const handleCreateEvaluationNode = async () => {
    await createNode(nodeData)
  }
  return (
    <div className="w-full">
      {isLoadingCreate && <BeatLoader color="#086e7c" />}
      {isSuccessCreate && <IndexAlert type="success" text="Création de nouveau noeud avec succée" />}
      {isErrorCreate && <IndexAlert type="danger" text={JSON.stringify(error?.data?.error)} />}

      <div className="md:w-1/2 w-4/5 px-3 mb-6 md:mb-0 mt-1 relative rounded-sm ">
        <label
          htmlFor="price"
          className="block text-lg font-medium text-gray-800">
          {t('admin.survey.evaluation_model.create.fields.node_name')}
        </label>
        <input
          type="text"
          name="price"
          id="price"
          className="focus:ring-teal-500 w-1/2 focus:border-teal-500
          block  w-full pl-2 pr-5 tsext-lg  text-[#444444] rounded-sm"
          style={{ border: '1px solid #e8e6e6' }}
          value={nodeData?.category?.text || ''}
          onChange={(e) => setNodeData({ ...nodeData, category: { ...nodeData.category, text: e.target.value, reference: e.target.value } })}
        />
      </div>
      <div className="md:w-1/2 w-4/5 px-3 mt-1 relative rounded-sm">
        <label className="block text-lg font-medium text-gray-800">
          {t('admin.survey.evaluation_model.create.fields.node_weight')}
        </label>
        <input
          type="text" name="price" id="price"
          className="focus:ring-teal-500 focus:border-teal-500 block max-w-fit pl-2 pr-2 text-lg text-[#444444] rounded-sm"
          style={{ border: '1px solid #e8e6e6' }}
          onKeyPress={(event) => {
            if (!/[0-9.]/.test(event.key) || (event.key === '.' && event.target.value.includes('.'))) {
              event.preventDefault()
            }
          }}
          value={nodeData?.category?.properties?.weight || null}
          onChange={(e) => setNodeData({ ...nodeData, category: { ...nodeData.category, properties: { weight: e.target.value } } })}
        />
      </div>

      <div className="md:w-1/2 w-4/5 px-3 mt-1 relative rounded-sm ">
        <label className="block text-lg font-medium text-gray-800">
          {t('admin.survey.evaluation_model.create.fields.scoringMethod')}
        </label>
        <select
          className="focus:ring-teal-500 focus:border-teal-500 block max-w-fit pl-2 pr-2 text-lg text-[#444444] rounded-sm"
          style={{ border: '1px solid #e8e6e6' }}
          value={nodeData?.category?.scoringMethod}
          onChange={(e) => setNodeData({ ...nodeData, category: { ...nodeData.category, scoringMethod: e.target.value } })}
        >
          <option></option>
          {scoringMethodList && scoringMethodList.map((node) => (
            <option key={node} value={node}>{node}</option>
          ))}
        </select>
      </div>
      <div className="md:w-5/6  px-3 mt-1 relative rounded-sm ">
        <label className="block text-lg font-medium text-gray-800">
          {t('admin.survey.evaluation_model.create.fields.questionsList')}
        </label>
         <QuestionList questions={questions || []} handleAddQuestions={handleAddQuestions} questionsList={nodeData?.questions || []}/>
      </div>
      <div className='w-3/4 mt-3 flex justify-end gap-2'>
        <button
          onClick={() => handleCreateEvaluationNode()}
          className="bg-teal-600 px-2 py-0.5 rounded-md mb-1 text-gray-100 text-md font-medium float-right p-1">
          <h5 className="text-white  w-16">{t('common.button.create')}</h5>
        </button>
        <button
          onClick={() => handleClose()}
          className="bg-gray-200 px-2 py-0.5 rounded-md mb-1 text-gray-100 text-md font-medium float-right p-1">
          <h5 className="text-black  w-16">{t('common.button.close')}</h5>
        </button>
      </div>
    </div>
  )
}
export default CreateEvaluationMethodNode

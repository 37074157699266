import { createSelector, createEntityAdapter } from '@reduxjs/toolkit'
import { apiSlice } from '../api/apiSlice'

const entitiesAdapter = createEntityAdapter({
})
const initialState = entitiesAdapter.getInitialState({
  data: '',
  status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
  count: 0
})

export const entitiesClientApiSlice = apiSlice.injectEndpoints({
  reducerPath: 'entitiesClientSlice',
  endpoints: builder => ({
    getEntitiesOwner: builder.query({
      query: (id) => `entity/entities/owner/${id}`,
      transformResponse: responseData => {
        const finalEntities = responseData
        return entitiesAdapter.setAll(initialState, finalEntities)
      },
      providesTags: ['Entities']
    }),
    getEntitiesConsultant: builder.query({
      query: (id) => `entity/entities/consultant/${id}`,
      transformResponse: responseData => responseData ,
      providesTags: ['Entities']
    }),
    getEntities: builder.query({
      query: () => 'entity/entities',
      transformResponse: responseData => {
        const finalEntities = responseData
        return entitiesAdapter.setAll(initialState, finalEntities)
      },
      providesTags: ['Entities']
    })
  })
})

export const {
  useGetEntitiesOwnerQuery,
  useGetEntitiesConsultantQuery,
  useGetEntitiesQuery
} = entitiesClientApiSlice

export const selectEntitiesAdminResult = entitiesClientApiSlice.endpoints.getEntitiesOwner.select()

export const selectEntitiesAdminData = createSelector(
  selectEntitiesAdminResult,
  entitiesResult => entitiesResult.data
)

export const {
  selectAll: selectAllEntities,
  selectById: selectSingleEntity
} = entitiesAdapter.getSelectors(state => selectEntitiesAdminData(state) ?? initialState)

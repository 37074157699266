import React, { useState } from 'react'
import { useGetCampaignsQuery } from '../../../features/campaign/campaignsSlice'
import { Spinner } from '../../../components/loadData/spinner'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import IndexAlert from '../../../components/alerts/indexAlert'
import CreateTransformation from './components/createTransformation'
import HistoryIcon from '@mui/icons-material/History'
import SelectEvaluationMethods from '../../../features/evaluationMethod/components/selectEvaluationMethods'
import { useGetEvaluationMethodQuery, useGetEvaluationMethodsQuery } from '../../../features/evaluationMethod/evaluationMethodSlice'
import SelectEvaluations from '../../../features/evaluationMethod/components/selectEvaluations'
import { useGetEvaluationsQuery } from '../../../features/evaluation/evaluationSlice'

function CreateEvaluation() {
  const { t } = useTranslation()
  const { idEntity } = useParams()
  const { data: campaigns, isSuccess, isLoading, isError, Error } = useGetCampaignsQuery(idEntity)
  const [evaluation, setEvaluation] = useState({ name: '', campaigns: [], entity: idEntity, evaluationMethod: '' })
  const { data: evaluationMethod } = useGetEvaluationMethodQuery(evaluation?.evaluationMethod || null)
  const { data: evaluationMethods } = useGetEvaluationMethodsQuery()
  const [evaluationInstance, setEvaluationInstance] = useState()
  const { data: evaluations } = useGetEvaluationsQuery(idEntity)
  const navigate = useNavigate()
  const now = new Date()
  const year = now.getFullYear()
  const month = String(now.getMonth() + 1).padStart(2, '0') // Months are 0-based in JavaScript
  const day = String(now.getDate()).padStart(2, '0')
  const hours = String(now.getHours()).padStart(2, '0')
  const minutes = String(now.getMinutes()).padStart(2, '0')
  const dateTimeString = `${year}${month}${day}-${hours}${minutes}-evaluation`
  if (!evaluation.name) {
    evaluation.name = dateTimeString
  }
  if (isLoading) {
    return (
      <div className='flex justify-center'>
        <Spinner text="hell" />
      </div>)
  }
  if (isSuccess) {
    return (
      <div className='w-full flex justify-center'>
        <div className="md:w-2/3 lg:w-2/3 sm:w-2/3 bg-wite md:mx-8 pb-8 mt-8">
          <h5 className="text-2xl font-medium text-[#444444] w-max-fit">{t('consultant.evaluation.create.create_evaluation')}</h5>
          <div className="mt-1 shadow-sm rounded-md w-full mx-2 bg-white px-4 py-4 flex flex-col gap-2" >
            <div className='w-full flex justify-between'>
              <div className="md:w-2/3 w-4/5 md:mb-0 mt-1 relative rounded-sm ">
                <label htmlFor="price" className="block text-lg font-medium text-gray-800">
                  {t('consultant.evaluation.create.evaluation_reference')}
                </label>
                <input
                  type="text" name="price" id="price" className="focus:ring-teal-500
                  focus:border-teal-500 block  w-full py-0.5 pl-2 pr-12 text-lg  text-[#444444] rounded-sm border-1 border-gray-300"
                  style={{ border: '1px solid #e8e6e6' }}
                  onChange={(e) => setEvaluation({ ...evaluation, name: e.target.value })}
                  value={evaluation?.name || ''} />
              </div>
              {evaluationInstance?.status === 'transform' &&
                <div>
                  <HistoryIcon sx={{ color: 'orange', cursor: 'pointer' }} onClick={() => navigate(`/hub/entity/${idEntity}/evaluation/note_calculation/${evaluationInstance.id}`)} />
                </div>}
            </div>

            <SelectEvaluationMethods onChange={(val) => setEvaluation({ ...evaluation, evaluationMethod: val })} evaluationMethods={evaluationMethods || []} evaluation={evaluation} />
            {evaluationMethod?.add_evaluation &&
              <>
                <IndexAlert text={evaluationMethod?.add_evaluation_notification || ''} type="warning" />
                <div className='flex flex-col gap-3 w-1/2'>
                  <div className='flex gap-2'>
                    {evaluationMethod?.added_evaluation_rules?.map((rule, index) =>
                      <SelectEvaluations key={index} text={`consultant.evaluation.create.Méthode_${rule.label}`} evaluations={evaluations || null} onChange={(val) => setEvaluation({ ...evaluation, evaluations: { ...evaluation.evaluations, [rule.label]: val } })} evaluationMethods={evaluationMethods || []} evaluation={evaluation} />
                    )}
                  </div>
                </div>
              </>
            }
            {campaigns
              ? <div className="md:w-2/3 w-3/5 md:mb-0 relative rounded-sm">
                <label htmlFor="countries_multiple" className="block text-lg font-medium text-gray-800">{t('consultant.evaluation.create.selectCampaigns')}</label>
                <select multiple id="countries_multiple" className="border bg-white border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-teal-500 focus:border-teal-500 block
            w-full p-1.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-teal-500 dark:focus:border-teal-500"
                  onChange={(e) => { setEvaluation({ ...evaluation, campaigns: Array.from(e.target.selectedOptions, option => option.value) }) }}>
                  {Object.values(campaigns.entities)?.map((campaign, index) =>
                    <option value={campaign.id} key={index}>{campaign.name}</option>
                  )}
                </select>
              </div>
              : <div className=' w-3/5'><IndexAlert type="warning" text={t('consultant.evaluation.create.campaign_missing')} /></div>
            }
            <CreateTransformation getEvaluationIns={(val) => setEvaluationInstance(val)} evaluation={evaluation}>
              <button className="bg-teal-600 float-right w-max-fit px-2 py-0.5 rounded-md mb-1 text-gray-100 text-md font-medium" style={{ cursor: 'pointer' }} >
                {t('consultant.buttons.evaluate')}
              </button>
            </CreateTransformation>
          </div>
        </div>
      </div>
    )
  }
  if (isError) {
    return <div> {Error} </div>
  }
}

export default CreateEvaluation

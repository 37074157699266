import React from 'react'
import EntitiesList from '../../../features/entities/entitiesList'
import DynamicNavBar from '../../../components/Bars/DynamicNavBar'
import { generalOptions, entityOptions } from '../DynamicNavbarOptions'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { useGetAllAdminEntitiesQuery } from '../../../features/entities/entitiesSlice'
import { selectCurrentUser } from '../../../features/auth/authSlice'
import { useSelector } from 'react-redux'

function EntitiesTracking (props) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const user = useSelector(selectCurrentUser)
  const { data: entities } = useGetAllAdminEntitiesQuery(user?.id || null)

  return (
    <div className="w-full pb-16">
      <DynamicNavBar option="navbar.generalOptions.organisations" options={generalOptions} />
      <DynamicNavBar option="navbar.entityOptions.organisation_list" options={entityOptions} />
      <div className="w-full flex flex-col items-center gap-8  static mt-4" >
        <div className=" md:w-2/3 lg:w-2/3 sm:w-2/3 bg-wite md:mx-8 pb-8">
          <div className="mt-1 shadow-sm rounded-md w-full mx-2  bg-white px-4 py-4 flex flex-col gap-4">
            <div>
              <div className="my-5 flex justify-between w-full">
                <h5 className="text-2xl font-medium text-[#444444] w-max-fit">{t('admin.organisation.list.organisations_list')} </h5>
                <button className="bg-teal-600 px-2 py-0.5 rounded-md mb-1 text-gray-100 text-md font-medium"
                  style={{ cursor: 'pointer' }} onClick={() => navigate('/admin/entity/create')}> {t('admin.organisation.list.create_organisation')}</button>
              </div>
              <EntitiesList entityList={entities || []} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

EntitiesTracking.propTypes = {
  isSuccess: PropTypes.bool,
  entities: PropTypes.object
}

export default EntitiesTracking

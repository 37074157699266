import { createSelector, createEntityAdapter } from '@reduxjs/toolkit'
import { apiSlice } from '../api/apiSlice'

const entitiesAdminAdapter = createEntityAdapter({
})
const initialState = entitiesAdminAdapter.getInitialState({
  data: '',
  status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
  count: 0
})

export const entitiesApiSlice = apiSlice.injectEndpoints({
  reducerPath: 'entitiesSlice',
  tagTypes: ['Entities'],
  endpoints: builder => ({
    getEntitiesAdmin: builder.query({
      query: () => '/entity/entities',
      transformResponse: responseData => {
        return entitiesAdminAdapter.setAll(initialState, responseData)
      },
      providesTags: ['Entities']
    }),
    getAllAdminEntities: builder.query({
      query: () => '/entity/all',
      transformResponse: responseData => {
        return responseData
      },
      providesTags: ['Entities']
    }),
    getEntity: builder.query({
      query: (id) => `/entity/${id}`,
      transformResponse: responseData => {
        return responseData
      }
    }),
    getEntityconsultants: builder.query({
      query: (id) => `/entity/${id}/consultants`,
      transformResponse: responseData => {
        return responseData
      }
    }),
    getEntityData: builder.query({
      query: (id) => `/entity/entitydata/${id}`,
      transformResponse: responseData => {
        return responseData
      }
    }),
    createEntity: builder.mutation({
      query: (entityData) => ({
        url: '/entity/create',
        method: 'POST',
        body: entityData

      }),
      invalidatesTags: ['Entities']
    }),
    deleteEntity: builder.mutation({
      query: (idEntity) => ({
        url: `/entity/delete/${idEntity}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Entities']
    }),
    editEntity: builder.mutation({
      query: (entity) => ({
        url: `/entity/modify/${entity.id}`,
        method: 'PATCH',
        body: entity
      }),
      invalidatesTags: ['Entities']
    }),
    editEntityConsultant: builder.mutation({
      query: (entity) => ({
        url: `/entity/edit_consultant/${entity.id}`,
        method: 'POST',
        body: entity
      }),
      invalidatesTags: ['Entities']
    }),
    getEntityInfoSiren: builder.mutation({
      query: (siren) => ({
        url: `/entity/get_entity_siren/${siren}`,
        method: 'POST',
        body: siren
      }),
      invalidatesTags: ['Entities']
    })
  })
})

export const {
  useGetEntitiesAdminQuery,
  useGetEntityInfoSirenMutation,
  useGetAllAdminEntitiesQuery,
  useGetEntityconsultantsQuery,
  useGetEntityQuery,
  useGetEntityDataQuery,
  useCreateEntityMutation,
  useDeleteEntityMutation,
  useEditEntityMutation,
  useEditEntityConsultantMutation
} = entitiesApiSlice

export const selectEntitiesAdminResult = entitiesApiSlice.endpoints.getEntitiesAdmin.select()

export const selectEntitiesAdminData = createSelector(
  selectEntitiesAdminResult,
  entitiesResult => entitiesResult.data
)

export const {
  selectAll: selectAllEntities,
  selectById: selectSingleEntity
} = entitiesAdminAdapter.getSelectors(state => selectEntitiesAdminData(state) ?? initialState)

import React from 'react'
import DynamicNavBar from '../../../../components/Bars/DynamicNavBar'
import { generalOptions, surveyOptions, importOptions } from '../../DynamicNavbarOptions'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import ConfirmImportPop from '../components/confirmImportPop'
import IndexAlert from '../../../../components/alerts/indexAlert'

function ConfirmImportFile() {
  const { t } = useTranslation()

  const { state } = useLocation()
  const { reponse, file } = state


  const AlertType = (type) => {
    const types = { FATAL: 'danger', INFO: 'info', SUCCESS: 'success', MODIF: 'warning' }
    return types[type]
  }

  return (
    <div className="w-full pb-16">
      <DynamicNavBar option="navbar.generalOptions.surveys" options={generalOptions} />
      <DynamicNavBar option="navbar.surveyOptions.import_export" options={surveyOptions} />
      <div className="w-full pb-16">
        <DynamicNavBar option="navbar.surveyOptions.import_export" options={importOptions} />
        <div className="w-full flex flex-col items-center gap-8  static mt-4" >
          <div className="sm:w-11/12 bg-wite md:mx-8 pb-8">
            <h5 className="text-2xl font-medium text-[#444444] w-max-fit">{t('admin.survey.import_export.verification_page.import_confirmation')}</h5>
            <div className="mt-1 shadow-sm rounded-md w-full mx-2 bg-white px-4 py-4 flex flex-col gap-4">
              <div className="flex justify-between w-full">
                <div className="pl-2 pt-1 w-full flex justify-between">
                  {reponse &&
                    <div className='flex flex-col gap-2'>
                      {reponse && Object.entries(reponse).map(([key, value]) => (
                        <div key={key}>
                          <h2 className='text-xl'>{t(`admin.survey.import_export.verification_page.${key}`)}</h2>
                          <ul className='flex flex-col gap-1 pl-2'>
                            {value.length > 0
                              ? value.map((item, index) => (
                                <li key={index}>
                                  <IndexAlert type={AlertType(item.error_type)} text={t(item.text) + ' Ligne' + item.ligne} />
                                </li>
                              ))
                              : <IndexAlert type="success" text={t('admin.survey.import_export.verification_page.empty_import_mistake')} />}
                          </ul>
                        </div>
                      ))
                      }
                      <div className='flex gap-2 float-right'>
                        <ConfirmImportPop file={file} confirmation={'reject'}>
                          <button className='bg-red-600 px-1 text-white'>{t('common.buttons.delete')}</button>
                        </ConfirmImportPop>
                        <ConfirmImportPop file={file} confirmation={'confirm'}>
                          <button className='bg-[#1b674c] px-1 text-white'>{t('common.buttons.confirm')}</button>
                        </ConfirmImportPop>
                      </div>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}

export default ConfirmImportFile

import React from 'react'
import { Outlet, Routes, Route } from 'react-router-dom'
import CreateSurvey from './createSurvey'
import QuestionTracking from './questionTracking'
import SurveyTracking from './surveyTracking'
import SettingsQuestion from './settingsQuestion'
import ModifQuestion from './components/modifQuestion'
import StartCreate from './startCreate'
import DetailQuestion from './components/detailQuestion'
import ExportSurvey from './exportSurvey'
import ExportedHistory from './exportedHistory'
import UsedSuveys from './usedSuveys'
import ImportIndex from './import/importIndex'
import ResponseType from './responseType'
import ModifModel from './components/modifModel'
import SlideBar from '../../../appComponents/questions/slideBar/slideBar'
import TransformModel from './transformResponse/transformModel'
import StartCreateTransformationModel from './transformResponse/startCreateTransformationModel'
import TransformModelTracking from './transformResponse/transformModelTracking'
import ConfirmImportFile from './import/confirmImportFile'
import StartCreateEvaluationModel from './evaluationMethod/startCreateEvaluationModel'
import EvaluationMethodTracking from './evaluationMethod/evaluationMethodTracking'
import EvaluationMethod from './evaluationMethod/evaluationMethod'
import CreateSurveyNew from './createSurveyNew'

function IndexSurvey () {
  return (
  <>
    <Outlet/>
    <Routes>
        {// <Route path="create/:id" exact element={<CreateSurvey/>}></Route>
        // <Route path="evaluation_method/create/:idModel" exact element={<EvaluationMethod/>}></Route>
        }
        <Route path="start_create" exact element={<StartCreate/>}></Route>
        <Route path="transformation_model/create/:idTransform" exact element={<TransformModel/>}></Route>
        <Route path="transformation_model/start_create" exact element={<StartCreateTransformationModel/>}></Route>
        <Route path="transformation_model" exact element= {<TransformModelTracking/>}></Route>

        <Route path="evaluation_method/create/:idModel" exact element={<EvaluationMethod/>}></Route>
        <Route path="evaluation_method/start_create" exact element={<StartCreateEvaluationModel/>}></Route>
        <Route path="evaluation_methods" exact element={<EvaluationMethodTracking/>}></Route>

        <Route path="create/:id" exact element={<CreateSurveyNew/>}></Route>

        <Route path="export/:idSurvey" exact element={<ExportSurvey/>}></Route>
        <Route path="export/historic" exact element={<ExportedHistory/>}></Route>
        <Route path="questions" exact element= {<QuestionTracking/>}></Route>
        <Route path="questions/responses_model" exact element= {<ResponseType/>}></Route>
        <Route path="questions/responses_model/:id/edit" exact element= {<ModifModel/>}></Route>
        <Route path="import" exact element= {<ImportIndex/>}></Route>
        <Route path="confirm_import" exact element= {<ConfirmImportFile/>}></Route>
        <Route path="questions/create" exact element= {<QuestionTracking/>}></Route>
        <Route path="questions/parametres" exact element={<SettingsQuestion/>}></Route>
        <Route path="question/:id/edit" exact element={<ModifQuestion/>}></Route>
        <Route path="question/:id/details" exact element={<DetailQuestion/>}></Route>
        <Route path="used" exact element={<UsedSuveys/>}></Route>
        <Route path="slide" exact element={<SlideBar/>}></Route>
        <Route path="" exact element={<SurveyTracking/>}></Route>

    </Routes>
</>
  )
}

export default IndexSurvey

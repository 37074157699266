import React from 'react'
import DynamicNavBar from '../../../../components/Bars/DynamicNavBar'
import { generalOptions, surveyOptions, transformModel } from '../../DynamicNavbarOptions'
import { useGetEvaluationMethodQuery } from '../../../../features/evaluationMethod/evaluationMethodSlice'
import { useTranslation } from 'react-i18next'
import EvaluationMethodAccordion from '../evaluationMethodAccordion'
import { useParams } from 'react-router-dom'
import LinkEvaluationMethodNode from './linkEvaluationMethodNode'
import CreateEvaluationMethodNode from './createEvaluationMethodNode'
import ModifyEvaluationMethodNode from './modifyEvaluationMethodNode'
import DeleteEvaluationMethodNode from './deleteEvaluationMethodNode'
import { useGetQuestionsListQuery } from '../../../../features/questions/questionSlice'

function EvaluationMethod () {
  const initialNodeData = { text: '', type: '', uuid: '', reference: '', scoringMethod: '', evaluation_node: '', evaluation_method: '', child: '', parent: '', properties: { weight: '1.0' } }
  const initialNodeDataCreateCategory = { category: { ...initialNodeData }, questions: [] }
  const initialChosedNode = { node: '', action: '' }
  const scoringMethodList = ['weightedAverage', 'value']
  const { t } = useTranslation()
  const { idModel } = useParams()
  const { data: evaluationMethod, isSuccess } = useGetEvaluationMethodQuery(idModel || null)
  const [chosenNode, setChosedNode] = React.useState(initialChosedNode)
  let [nodeData, setNodeData] = React.useState(initialNodeData)
  let [createNodeData, setCreateNodeData] = React.useState(initialNodeDataCreateCategory)
  const { data: questions } = useGetQuestionsListQuery()

  const handlemodify = (id) => {
    setChosedNode({ node: id, action: 'update' })
    const nodes = evaluationMethod?.survey_tree?.trees[0]?.nodes
    const links = evaluationMethod?.survey_tree?.trees[0]?.links
    const selectedNode = nodes.find(node => node.uuid === id)
    const selectedNodeLink = links.find(node => node.child === id)
    if (selectedNode) {
      setNodeData({
        text: selectedNode?.text,
        type: selectedNode?.type,
        uuid: selectedNode?.uuid,
        reference: selectedNode?.reference,
        scoringMethod: selectedNode?.scoringMethod,
        evaluation_node: selectedNode?.evaluation_node,
        evaluation_method: selectedNode?.evaluation_method,
        evaluation_model: idModel,
        child: id,
        parent: selectedNodeLink?.parent,
        properties: selectedNodeLink?.properties
      })
    }
  }

  const handlecreate = (id) => {
    console.log('hello', { ...initialNodeDataCreateCategory, category: { ...initialNodeData, parent: id, evaluation_model: idModel } })
    setCreateNodeData(createNodeData = { ...initialNodeDataCreateCategory, category: { ...initialNodeData, parent: id, evaluation_model: idModel } })
    setChosedNode({ node: id, action: 'create' })
  }
  const handlelink = (id) => {
    setNodeData({ ...initialNodeData, parent: id, evaluation_model: idModel })
    setChosedNode({ node: id, action: 'link' })
  }
  const handledelete = (id) => {
    setNodeData({ ...initialNodeData, uuid: id, evaluation_model: idModel })
    setChosedNode({ node: id, action: 'delete' })
  }
  const handleClose = () => {
    setChosedNode(initialChosedNode)
    setNodeData(initialNodeData)
  }

  return (
    <div className="w-full pb-16">
      <DynamicNavBar option="navbar.generalOptions.surveys" options={generalOptions}/>
      <DynamicNavBar option="navbar.surveyOptions.tranformation_model" options={surveyOptions}/>
      <DynamicNavBar option="navbar.transformModel.evaluation_method_list" options={transformModel}/>
      <div className="mt-2 w-full h-screan">
    <h5 className="text-center text-3xl font-medium text-[#444444] w-max-fit mb-3">{t('admin.survey.evaluation_model.title.create_evaluation_method')}</h5>
    <div className='w-full flex'>
      <div className='w-1/4 border-r-2 border-gray-300 px-2 h-screen'>
      {isSuccess && <EvaluationMethodAccordion surveyTree= {evaluationMethod?.survey_tree || []} handlemodify={handlemodify} handlecreate={handlecreate} handledelete={handledelete} handlelink={handlelink} /> }
      </div>
      <div className='w-3/4 px-3 h-screen'>
      {chosenNode.node &&
        <>
          {chosenNode.action === 'link' &&
            <div className="col-span-5 p-1 w-full h-full bg-[#fff] ">
              <LinkEvaluationMethodNode surveyNode={chosenNode} nodeData={nodeData} setNodeData={setNodeData} setChosedNode={setChosedNode} handleClose={handleClose} scoringMethodList={scoringMethodList}/>
             </div>}
          {chosenNode.action === 'create' &&
            <div className="col-span-5 p-1 w-full h-full bg-[#fff] ">
              <CreateEvaluationMethodNode questions={questions || []} surveyNode={chosenNode} nodeData={createNodeData} setNodeData={setCreateNodeData} setChosedNode={setChosedNode} handleClose={handleClose} scoringMethodList={scoringMethodList}/>
             </div>}
          {chosenNode.action === 'update' &&
            <div className="col-span-5 p-1 w-full h-full bg-[#fff] ">
              <ModifyEvaluationMethodNode surveyNode={chosenNode} nodeData={nodeData} setNodeData={setNodeData} setChosedNode={setChosedNode} handleClose={handleClose} scoringMethodList={scoringMethodList}/>
            </div>}
          {chosenNode.action === 'delete' &&
            <div className="col-span-5 p-1 w-full h-full bg-[#fff] ">
              <DeleteEvaluationMethodNode surveyNode={chosenNode} nodeData={nodeData} setNodeData={setNodeData} setChosedNode={setChosedNode} handleClose={handleClose}/>
            </div>}
        </>
          }

      </div>
    </div>
    </div>
    </div>
  )
}
export default EvaluationMethod

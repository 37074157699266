import React from 'react'
import { useTranslation } from 'react-i18next'

function SelectParam ({ selectFct, data, title }) {
  const { t } = useTranslation()
  return (
    <div>
    <h5 className="text-md font-medium text-[#444444]">{title}</h5>
    <div className='w-full'>

    <select className="p-2 border border-gray-300 rounded-md min-w-[50%]" onChange={(e) => {
      selectFct(e.target.value)
    }}>
          {data && data.map((value) =>
            <option key={value.filter_name} value={value.filter_name}>{t(`consultant.materiality_matrix.labels.${value.filter_name}`)}</option>)}
        </select>
    </div>
    </div>
  )
}

export default SelectParam

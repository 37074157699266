import React from 'react'
import { useTranslation } from 'react-i18next'
import InputInfo from './inputInfo'

function EvaluationInfo ({ evaluation }) {
  const { t } = useTranslation()
  return (
        <div className="flex justify-left w-full mt-2 gap-24 mb-2">
            <div className="max-w-fit  flex-col bg-white rounded-md justify-between p-1 pt-2 h-400 gap-4 pr-8  pl-2 ">
                <InputInfo input_value={evaluation?.entity || ''} text={'consultant.evaluation.result.entity_name'} type={'text'} disabled={true}/>
                <div>
                    <h5 className="form-check-label inline-block text-gray-800 text-lg " htmlFor="flexCheckChecked" style={{ cursor: 'pointer' }}>
                        {t('consultant.evaluation.result.comapign_list')}
                    </h5>
                    {evaluation?.campaigns && evaluation?.campaigns.map((campaign, index) => <div key={index} className='bg-gray-400 max-w-fit px-2'>{campaign}</div>)}
                </div>
                <div className='max-w-fit'>
                    <h5 className="text-gray-800" htmlFor="flexCheckChecked" style={{ cursor: 'pointer' }}>
                        {t('consultant.evaluation.result.allow_matrix')}
                    </h5>
                    <input type="checkbox" className="w-10" checked={evaluation?.allow_matrix || false} disabled={true}/>
                </div>
                <InputInfo input_value={evaluation?.evaluationMethod || '' } text={'consultant.evaluation.result.transform_method'}
                type={'text'} disabled={true}/>

                <InputInfo input_value={evaluation?.matrix_module_name || '' } text={'consultant.evaluation.result.matrix_module_name'}
                type={'text'} disabled={true}/>

                <InputInfo input_value={evaluation?.tarnsformMethod || '' } text={'consultant.evaluation.result.matrix'} type={'text'} disabled={true}/>
            </div>
        </div>
  )
}

export default EvaluationInfo

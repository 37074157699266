import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { REHYDRATE } from 'redux-persist'
import { setCredentials, logout } from '../auth/authSlice'
import { baseURL } from '../../var.conf'

const baseQuery = fetchBaseQuery({
  baseUrl: baseURL,
  prepareHeaders: (headers, { getState }) => {
    const token = getState().auth.token
    if (token) {
      headers.set('authorization', 'bearer ' + token)
    }
    return headers
  }
})

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions)
  if (result?.error?.status === 403 || result?.error?.status === 401) {
    const refreshResult = await baseQuery('/o/token', api, extraOptions)
    if (refreshResult?.data) {
      const user = api.getState().auth.user
      api.dispatch(setCredentials({ ...refreshResult.data, user }))
      result = await baseQuery(args, api, extraOptions)
    } else {
      api.dispatch(logout())
      api.dispatch(setCredentials({}))
      if (extraOptions && extraOptions.navigate) {
        extraOptions.navigate('/auth/login')
      }
    }
  }
  return result
}

export const apiSlice = createApi({
  baseQuery: baseQueryWithReauth,
  refetchOnMountOrArgChange: true,
  refetchOnReconnect: true,
  extractRehydrationInfo (action, { reducerPath }) {
    if (action.type === REHYDRATE) {
      return action.payload?.[reducerPath]
    }
  },
  tagTypes: ['Entity', 'TransformMethod', 'Campaigns', 'Survey', 'Entities', 'materialityMatrix',
    'SurveyTrees', 'Response', 'Activities', 'Question', 'Option','SurveyNodes',
    'OptionTemplate', 'TagQuestion', 'CreatedSurvey', 'Alerts', 'ExportedSurveys', 'QuestionsText', 'Documents'],
  endpoints: builder => ({})
})

import React from 'react'
import BookIcon from '@mui/icons-material/Book'
import { useGetCampaignQuery, useCampaignChangeShowTypeMutation } from './campaignsSlice'
import { useGetUserCampaignResponsesQuery } from '../../features/surveyCampaign/userCampaignResponseSlice'
import { Spinner } from '../../components/loadData/spinner'
import CampaignDetailUserList from './utils/table/CampaignDetailUserList'
import UserResponseState from './userResponseState'
import { selectCurrentUser } from '../../features/auth/authSlice'
import { useSelector } from 'react-redux'
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

function CampaignData ({ campaign }) {
  const { t } = useTranslation()

  const [type, setType] = React.useState()
  const { campaignId } = useParams()
  const navigate = useNavigate()
  const { data: userCampaignResponse } = useGetUserCampaignResponsesQuery(campaignId)

  const [campaignChangeShowType] = useCampaignChangeShowTypeMutation()

  const [member, setMember] = React.useState()
  const [userStats, setUserStats] = React.useState()
  const [memberList, setMemberList] = React.useState()
  const user = useSelector(selectCurrentUser)
  React.useEffect(() => {
    if (campaign) {
      if (campaign?.users_list?.users_list) {
        setMemberList(Object.values(campaign?.users_list.users_list))
      }
      setType(campaign.show_tree)
      const member = Object.values(campaign?.users_list.users_list).filter((us) => us.email === user.email)
      if (member.length > 0) {
        setMember(member[0])
      }
    }
  }, [campaign, user.email])

  const changeType = (e) => {
    setType(!type)
    campaignChangeShowType({ id: campaignId, type: !type })
  }
  return (
    <div className="w-full flex justify-center px-auto ">
      <div className="md:w-3/4 lg:w-3/4 sm:w-3/4 bg-wite md:mx-8 pb-8 mt-4">
        <div className="flex">
          <div className='flex'>
            <BookIcon sx={{ color: '#5b5b5b', fontSize: 25 }} />
            <h5 className="text-xl font-medium text-[#444444]">{campaign?.name || ''}</h5>
          </div>
          {member &&
            <div className="ml-4 flex w-full justify-end gap-2 " style={{ cursor: 'pointer' }} onClick={() => navigate(`/survey/${campaignId}/${member.id}`)}>
              <h5 className="text-xl font-medium text-green-800 pt-0.5" style={{ cursor: 'pointer' }}>{t('consultant.campaign.create.answer_survey')}</h5>
              <AutoFixHighIcon sx={{ color: 'green', cursor: 'pointer' }}/>
            </div>
          }

        </div>
        <div className="flex flex-col gap-2 bg-white w-max-full  px-2 pt-2 p-2 mt-2 shadow-sm pl-6 min-h-fit pb-14">
          <div>
            <h5 className="text-xl font-medium text-[#444444]">{t('common.fields.description')}</h5>
            <div className="text-md max-h-150 md:w-500 w-500 h-full overflow-auto border-2 border-gray-100 bg-gray-50 p-2 pl-3 mt-1 ml-2">
              <p className=" text-[#444]">{campaign?.description || ''}</p>
            </div>
          </div>
          <div className="md:w-full">
            <h5 className="text-xl font-medium text-[#444444] ">{t('consultant.campaign.create.usersList')}</h5>
            <div className="flex mb-2">
              <input
                className="form-check-input  border  text-white border-gray-300 rounded-sm bg-white checked:bg-teal-600 checked:border-teal-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                type="checkbox" style={{ width: '19px', height: '19px' }} id="flexCheckDefault"
                checked={!!type} onChange={(e) => changeType(e)} />
              <label className="text-md pt-0.5 font-medium text-[#444444]">{t('consultant.campaign.create.tree_view')}</label>
            </div>
            <div className="pl-2 ">
              { <CampaignDetailUserList members={memberList || undefined} setUserStats={setUserStats} />
              }
            </div>
            {userStats && <UserResponseState userStats={userStats || undefined} />
          }
          </div>
        </div>
      </div>
    </div>
  )
}

function CampaignEdit () {
  const { campaignId } = useParams()
  const [campaign, setCampaign] = React.useState(null)
  const { data: campaignData, isSuccess, isLoading, isError, Error } = useGetCampaignQuery(campaignId)
  React.useEffect(() => {
    if (campaignData && isSuccess) {
      setCampaign(campaignData)
    } else {
      setCampaign(null)
    }
  }, [campaignData, isSuccess])

  let content
  if (isLoading) {
    content = <Spinner />
  } else if (isSuccess) {
    if (campaign) {
      content = <CampaignData campaign={campaign} />
    }
  } else if (isError) {
    content = <div>{Error}</div>
  }
  return (
    <>{content}</>
  )
}
export default CampaignEdit

import React from 'react'
import DualListBoxComponent from './dualListBox'
import { selectAllSurveys, useGetAllSurveysQuery } from '../../surveys/surveysSlice'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

function AddSurveys ({ onChangeEntity, entity, onChangeStep }) {
  const { t } = useTranslation()
  const { data } = useGetAllSurveysQuery()
  const [surveys, setSurveys] = React.useState()
  const surveysTrees = useSelector(selectAllSurveys)
  React.useEffect(() => {
    if (surveysTrees) {
      setSurveys(surveysTrees)
    }
  }, [surveysTrees])

  return (
    <section>
      <div className="flex flex-col  flex-wrap gap-2 mt-4">
          <DualListBoxComponent surveys={surveys} entity={entity} onChangeEntity={onChangeEntity}/>
      </div>
    </section>
  )
}

export default AddSurveys

import React from 'react'
import 'reactjs-popup/dist/index.css'
import BeatLoader from 'react-spinners/BeatLoader'

import IndexAlert from '../../../../components/alerts/indexAlert'
import { useTranslation } from 'react-i18next'
import { useDeleteNodeMutation } from '../../../../features/evaluationMethod/evaluationMethodSlice'

function DeleteEvaluationMethodNode ({surveyNode, nodeData, setNodeData, handleClose }) {
  const [deleteNode, { isSuccess, isLoading, isError, error }] = useDeleteNodeMutation()
  const { t } = useTranslation()
  const confirmRef = React.useRef()
  const [confirm, setConfim] = React.useState()
  const handleDeleteNode = async () => {
    await deleteNode(nodeData)
  }
  return (
    <div className="modal">
    <div className="content flex flex-col justify-center items-center ">
        <h5 className="#444 text-lg text-center"><span className="text-red-600">{t('admin.survey.surveys.create.Attention')} </span>{t('admin.survey.surveys.create.deleteNodeWarning')}</h5>
        <h5 className="#444 text-lg ">{t('Confirm')} <span className="text-red-600">{t('admin.survey.surveys.create.Confirm')}</span></h5>
        <div className="mt-2">
          {isLoading && <BeatLoader color="#086e7c"/>}
          {isSuccess && <IndexAlert type="success" text={t('admin.survey.surveys.create.node_delete')}/>}
        {isError && <div><IndexAlert type="danger" text={JSON.stringify(error.data.error)}/> </div>}
        </div>
        {(!isLoading && !isSuccess) &&
          <input ref={confirmRef} onChange={(e) => setConfim(e.target.value)} id="confirm" className=" border-1 border-gray-300 pl-2 py-0.5 w-200"/>
        }
    </div>
    <div className='justify-center flex gap-2'>
    {confirm === 'Confirm'
      ? (isLoading || isSuccess)
          ? undefined
          : <button onClick={() => handleDeleteNode()} className="bg-red-800 text-white p-1 text-lg px-1" style={{ cursor: 'pointer' }}>
    {t('common.button.delete')}</button>
      : undefined
    }
    <button
        onClick={() => handleClose()}
        className="bg-gray-200 text-black p-1 text-lg px-1">
        <h5 className="text-black  w-16">{t('common.button.close')}</h5>
      </button>
    </div>
  </div>
  )
}

export default DeleteEvaluationMethodNode
import React from 'react'
import { useGetCustomersQuery, selectAllCustomers } from '../../customers/customersSlice'
import { useSelector } from 'react-redux'
import { Spinner } from '../../../components/loadData/spinner'
import ConsultantsTable from './consultantsTable'
import { useTranslation } from 'react-i18next'

function SetEntityConsultants({ onChangeEntity, entity }) {
  const { isLoading, isSuccess, isError, error } = useGetCustomersQuery()
  const consultants = useSelector(selectAllCustomers)
  const { t } = useTranslation()
  React.useEffect(() => {
    if (!entity.consultants) {
      onChangeEntity({ ...entity, consultants: [] })
    }
  }, [])

  if (isLoading) {
    return <Spinner />
  }
  if (isSuccess) {
    return (
      <section className="w-full py-4">
        <div className="flex flex-col w-full">
          <h5 htmlFor="price" className="inline-block text-gray-900 py-2">{t('admin.organisation.create.fields.select_multiple_consultants')} </h5>
          <ConsultantsTable onChangeEntity={onChangeEntity} entity={entity} consultants={consultants} modify={true}/>
        </div>
      </section>
    )
  }
  if (isError) {
    return <h5>{error}</h5>
  }
}

export default SetEntityConsultants
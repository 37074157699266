import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../auth/authSlice'
import { useNavigate } from 'react-router-dom'
import { useVerifytokenQuery } from '../auth/authApiSlice'

const useSessionManager = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const token = useSelector(state => state.auth.token)
  const user = useSelector(state => state.auth.user)
  const { data, error, refetch, isFetching } = useVerifytokenQuery(token, { skip: !token })

  useEffect(() => {
    if (!token) return

    // Refetch immediately on mount
    refetch()

    const intervalId = setInterval(() => {
      refetch()
    }, 60000) // Adjust the interval as needed

    return () => clearInterval(intervalId)
  }, [token, refetch])

  useEffect(() => {
    if (!token) return

    // Delay the initial verification to allow refetch to complete
    const initialVerificationTimeout = setTimeout(() => {
      if (!isFetching && (data?.status !== 'valid' || error)) {
        dispatch(logout())
        navigate('/auth/login')
      }
    }, 1000) // Adjust delay as needed

    return () => clearTimeout(initialVerificationTimeout)
  }, [token, data, error, isFetching, dispatch, navigate])

  
}

export default useSessionManager
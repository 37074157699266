import React from 'react'
import { useTranslation } from 'react-i18next'

function SelectParams ({ selectFct, data, title }) {
  const { t } = useTranslation()
  return (
    <div>
    <h5 className="text-md font-medium text-[#444444]">{title}</h5>
    <div className='flex gap-2 w-full'>

    <select className="p-2 border border-gray-300 rounded-md min-w-[50%]" onChange={(e) => {
      const selectedValue = JSON.parse(e.target.value) // Parse the selected value back to an object
      selectFct(selectedValue)
    }}>
          {data && data.filter((e) => e.type === 'axis_box_1').map((value) =>
            <option key={value.filter_name} value={JSON.stringify(value)}>{t(`consultant.materiality_matrix.labels.${value.filter_name}`)}</option>)}
        </select>
    <select className="p-2 border border-gray-300 rounded-md min-w-[50%]" onChange={(e) => {
      const selectedValue = JSON.parse(e.target.value) // Parse the selected value back to an object
      selectFct(selectedValue)
    }}>
        {data && data.filter((e) => e.type === 'axis_box_2').map((value) =>
          <option key={value.filter_name} value={JSON.stringify(value)}>{t(`consultant.materiality_matrix.labels.${value.filter_name}`)}</option>)}
      </select>
    </div>
    </div>
  )
}

export default SelectParams

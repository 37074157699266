import React from 'react'
import { Outlet, Routes, Route } from 'react-router-dom'
import CreateCampaign from './createCampaign'
import Campaigns from './campaigns'
import NavBarHub from '../../../components/Bars/navBarHub'
import DetailUser from './detailUser'
import CampaignEdit from '../../../features/campaign/CampaignEdit'

function IndexCampaigns () {
  return (
    <div>
        <NavBarHub option={'campaign'}/>
        <div className="flex flex justify-center static">
          <div className="w-full">
            <Outlet/>
            <Routes>
                <Route path="create" element={<CreateCampaign/>}></Route>
                <Route path="" element={<Campaigns/>}></Route>
                <Route path="details/:campaignId" element={<CampaignEdit/>}></Route>
                <Route path="details/:idCampaign/:idUser" element={<DetailUser/>}/>
            </Routes>
          </div>
        </div>
    </div>
  )
}

export default IndexCampaigns

import { createSelector, createEntityAdapter } from '@reduxjs/toolkit'
import { apiSlice } from '../api/apiSlice'

const surveysAdapter = createEntityAdapter({
})
const initialState = surveysAdapter.getInitialState({
  data: '',
  status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
  count: 0
})

export const surveysApiSlice = apiSlice.injectEndpoints({
  reducerPath: 'surveytreesSlice',
  endpoints: builder => ({
    getAllSurveys: builder.query({
      query: () => 'survey/surveytrees',
      transformResponse: responseData => {
        const finalSurveys = responseData
        return surveysAdapter.setAll(initialState, finalSurveys)
      },
      providesTags: ['SurveyTrees']
    }),
    getSurveyList: builder.query({
      query: () => 'survey/surveylist',
      transformResponse: responseData => {
        return responseData
      },
      providesTags: ['SurveyTrees']
    }),
    getSurveyTreesList: builder.query({
      query: () => 'survey/surveytreesList',
      transformResponse: responseData => {
        return responseData
      },
      providesTags: ['SurveyTrees']
    }),

    getEntitySurveys: builder.query({
      query: (id) => `entity/${id}/surveys`,
      transformResponse: responseData => {
        return responseData
      },
      providesTags: ['SurveyTrees']
    }),
    exportSurvey: builder.mutation({
      query: (data) => ({
        url: '/survey/create',
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['SurveyTrees', 'ExportedSurveys']
    })
  })
})

export const {
  useGetAllSurveysQuery,
  useGetSurveyListQuery,
  useGetSurveyTreesListQuery,
  useGetEntitySurveysQuery,
  useExportSurveyMutation
} = surveysApiSlice

export const selectSurveyResult = surveysApiSlice.endpoints.getAllSurveys.select()

export const selectSurveyData = createSelector(
  selectSurveyResult,
  surveysResult => surveysResult.data
)

export const {
  selectAll: selectAllSurveys,
  selectById: selectSurveyById
} = surveysAdapter.getSelectors(state => selectSurveyData(state) ?? initialState)

import React from 'react'
import DualListBox from 'react-dual-listbox'
import { useTranslation } from 'react-i18next'
import 'react-dual-listbox/lib/react-dual-listbox.css'
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight'
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'

function DualListBoxComponent ({ entity, surveys, onChangeEntity }) {
  const { t } = useTranslation()

  const [selected, setSelected] = React.useState([])
  const [options, setOptions] = React.useState([])
  React.useEffect(() => {
    if (surveys) {
      const formatOptions = []
      surveys.map((survey) => {
        formatOptions.push({ value: survey.id, label: survey.name })
      })
      setOptions(formatOptions)
    }
  }, [surveys])

  React.useEffect(() => {
    if (surveys && entity?.survey_trees) {
      const surveysTrees = surveys.filter((survey) => entity.survey_trees.includes(survey.id))
      const formatSelected = []
      surveysTrees.map((survey) => {
        formatSelected.push(survey.id)
      })
      setSelected(formatSelected)
    }
  }, [entity?.survey_trees, surveys])

  const changeSelected = (its) => {
    onChangeEntity({ ...entity, survey_trees: its })
  }

  return (
            <DualListBox
                canFilter
                filterPlaceholder={t('common.table.search')}
                options={options}
                selected={selected}
                onChange={changeSelected}
                icons={{
                  moveLeft: <KeyboardArrowLeftIcon/>,
                  moveAllRight: <KeyboardDoubleArrowRightIcon/>,
                  moveRight: <KeyboardArrowRightIcon/>,
                  moveAllLeft: <KeyboardDoubleArrowLeftIcon/>
                }}
            />
  )
}
export default DualListBoxComponent

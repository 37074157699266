import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useGetEntityInfoSirenMutation } from '../entitiesSlice'
import EntityFields from './entityFields'
import { formatEntityInfos } from '../utils/formatEntityInfos'
import { useGetActivitiesQuery } from '../../../features/activitiesSector/activitiesSlice'
function EntityInfo ({ onChangeEntity, entity }) {
  const { t } = useTranslation()
  const [getEntityInfoSiren] = useGetEntityInfoSirenMutation()

  const { data: activivitySectors } = useGetActivitiesQuery()
  const handleSearchSiren = async () => {
    if (entity?.entityInfo?.siret) {
      const entityInfo = await getEntityInfoSiren(entity?.entityInfo?.siret || null)
      const entityInfoFormated = formatEntityInfos(entityInfo)
      const activivitySector = Object.values(activivitySectors.entities).find((op) => op.reference === entityInfoFormated.activitysector)
      onChangeEntity({ ...entity, entityInfo: { ...entity.entityInfo, ...entityInfoFormated, activitysector: activivitySector?.id || '' }, name: entityInfoFormated?.entityName || '' })
    } else {
      onChangeEntity({ ...entity, entityInfo: {}, name: '' })
    }
  }
  return (
    <section className='pb-2'>
      <div className="flex flex-col gap-2">
          <>
            <div className="md:w-1/2 w-4/5 px-3 md:mb-0 mt-1 relative rounded-sm">
              <label htmlFor="price" className="block text-lg font-medium text-gray-800"> {t('admin.organisation.create.fields.organisation_siret')} </label>
              <input type="text" name="org_identifier" id="price" className="focus:ring-teal-500 focus:border-teal-500 block  w-full pl-2 pr-5 tsext-lg  text-[#444444] rounded-sm"
                style={{ border: '1px solid #e8e6e6' }} value={entity?.entityInfo?.siret || ''}
                onChange={(e) => onChangeEntity({ ...entity, entityInfo: { ...entity.entityInfo, siret: e.target.value } })} />
            </div>

            <div className="w-full">
              <button className="bg-teal-600 mx-2 px-2 py-0.5 rounded-md mb-1 text-gray-100 text-md font-medium float-left"
                onClick={() => handleSearchSiren()} name="button_search_identifier">
                <h5 className="text-white  w-fit">
                  {t('admin.organisation.create.fields.recuperate_info')}
                </h5>
              </button>
            </div>
            </>
         <EntityFields entity={entity} onChangeEntity={onChangeEntity} />
      </div>
    </section>
  )
}
EntityInfo.propTypes = {
  onChangeEntity: PropTypes.func.isRequired,
  entity: PropTypes.object,
  onChangeStep: PropTypes.func.isRequired
}

export default EntityInfo

/*

*/

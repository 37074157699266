import React from 'react'
import { Outlet, Routes, Route } from 'react-router-dom'
import NavBarHub from '../../../components/Bars/navBarHub'
import DocumentsList from './documents'

function IndexDocument () {
  return (
    <div>
        <NavBarHub option={'documents'}/>
        <div className="flex flex justify-center static">
          <div className="w-full">
            <Outlet/>
            <Routes>
            <Route path="" element={<DocumentsList/>}></Route>
            </Routes>
          </div>
        </div>
    </div>
  )
}

export default IndexDocument
